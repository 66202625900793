import * as React from "react";
import { Grid, Typography, Box } from "@mui/material";

export default function NotificationMessage(props: any) {

    const { isCookiePresent, isMsgRequired } = props;

    // const [t] = useTranslation();

    return (
        <Box className={`notification_msg ${!isCookiePresent ? "msg_position" : ""}`}
            component="div"
        >
            <Grid>
                <Typography
                    variant="inherit"
                    noWrap
                    component="div"
                >
                    {isMsgRequired}
                    {/* {t("footer.notification_msg")} */}
                </Typography>
            </Grid>
        </Box>
    );
}