import React, { useEffect, useState, useRef } from "react";
import {
    Button,
    Container,
    Typography,
    Box,
    Grid,
    TextField,
    InputAdornment,
    IconButton,
    CircularProgress,
    Divider,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CONSTANTS, {
    LIST_LIMIT,
    OMS_CONSTANTS,
    SHEET_NAME,
} from "../../../constants/constants";
import { useTranslation } from "react-i18next";
import Pagination from "../../common/Pagination";
import { ROUTES } from "../../../constants/routes";
import Skeleton from "../../common/Skeleton";
import omsDownloadService from "../service/omsDownloadService";
import { commonActions } from "../../../redux/features/common/commonSlice";
import SearchIcon from "@mui/icons-material/Search";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Link } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SimpleReactValidator from "simple-react-validator";
import lazyTypingUtils from "../../../utils/lazyTypingUtils";
import jaLocale from "date-fns/locale/ja";
import downloadDataToExcel from "../../../utils/ExcelDownloadUtility";
import Utility from "../../../utils/Utility";
import { OMSConfig } from "../config/OMSConfig";
import DownloadButton from "../../common/DownloadBtn";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const OmsDownload = (props: any) => {
    const utility = new Utility;
    const { showSnackbar, handleLoader } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState(false);
    const shippedFilter = useAppSelector(
        (state: any) => state?.common?.backOrderFilter
    );
    const omsCurrentPage = useAppSelector((state: any) => state.common.orderStatus);
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const defaultLimit = LIST_LIMIT.LIMIT_15;
    const firstPage = CONSTANTS.FIRST_PAGE;
    const dispatch = useAppDispatch();
    const [t, i18n] = useTranslation();
    const [shippedData, setShippedData] = useState<any>([]);
    const [skeleton, setSkeleton] = useState(true);
    const [currentPage, setCurrentPage] = useState<any>(
        omsCurrentPage?.currentPage  && omsCurrentPage?.currentPage != "undefined" ? omsCurrentPage?.currentPage : 1
    );
    const [totalCount, setTotalCount] = useState<any>(0);
    const [totalPages, setTotalPages] = useState<any>(0);
    const [limit, setLimit] = useState(
        !perPageLimit ? defaultLimit : perPageLimit
    );
    const [isInvitationSent, setIsInvitationSent] = useState(false);
    const [, forceUpdate] = useState(0);
    const [dateRang, setDateRang] = useState<any>(
        shippedFilter?.dateRang ? shippedFilter?.dateRang : 7
    );
    const [statusCode, setStatusCode] = useState<any>(
        shippedFilter?.statusCode ? shippedFilter?.statusCode : ""
    );
    const [shipDate, setShipDate] = useState(
        shippedFilter?.shipDate ? shippedFilter?.shipDate : "AESC"
    );
    const [search, setSearch] = useState<string>(
        shippedFilter?.search ? shippedFilter?.search : ""
    );
    const [valueFrom, setValueFrom] = React.useState<Date | null>(
        shippedFilter?.valueFrom ? shippedFilter?.valueFrom : null
    );
    const [valueTo, setValueTo] = React.useState<Date | null>(
        shippedFilter?.valueTo ? shippedFilter?.valueTo : null
    );
    const [viewCustom, setViewCustom] = useState<boolean>(
        shippedFilter?.viewCustom ? shippedFilter?.viewCustom : false
    );
    const [fromParam, setFromParam] = useState<any>(
        shippedFilter?.fromParam ? shippedFilter?.fromParam : ""
    );
    const [toParam, setToParam] = useState<any>(
        shippedFilter?.toParam ? shippedFilter?.toParam : ""
    );
    const [filterChange, setFilterChange] = useState(false);

    const [statusAll, setStatusAll] = useState<any>(shippedFilter?.statusCode ? shippedFilter?.statusCode : "null");
 
    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        const handlePageRefresh = () => {
            dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER({}));
            dispatch(commonActions.IS_UNSHIPPED({ currentPage: 1, limit: 15 }));
            dispatch(commonActions.IS_SHIPPED({ currentPage: 1, limit: 15 }));
            dispatch(
                commonActions.IS_PREPARING_SHIPPMENT({ currentPage: 1, limit: 15 })
            );
            dispatch(commonActions.IS_CANCEL({ currentPage: 1, limit: 15 }));
            dispatch(commonActions.IS_DOWNLOAD({ currentPage: 1, limit: 15 }));
            dispatch(commonActions.IS_REFUND({ currentPage: 1, limit: 15 }));
        };
        window.addEventListener("beforeunload", handlePageRefresh);
        return () => {
            window.removeEventListener("beforeunload", handlePageRefresh);
        };
    }, []);

    useEffect(() => {
        forceUpdate(1);
    });

  
    const orderIdCompare: any[] = [];
    const [checked, setChecked] = useState(new Array(limit).fill(false));
    useEffect(() => {
        omsShippedData(currentPage, limit);
        totalPages != 0 ? setShippedData(currentPage) : null;
    }, []);

    // const omsStoreId = useAppSelector((state) => state.common.loggedInStoreId);

    useEffect(() => {
        if (isInvitationSent || dateRang || shipDate || statusCode) {
            if (filterChange) {
                omsShippedData(firstPage, limit);
            }
        }
    }, [isInvitationSent, dateRang, shipDate, statusCode]);

    const onsearchApicall = () => {
        if (filterChange || search !== "") {
            omsShippedData(firstPage, limit);
        }
    };

    const propItem = [onsearchApicall, search];
    lazyTypingUtils(propItem);

    const omsShippedData = (currentPage: number, limit: number) => {
        setFilterChange(false);
        setSkeleton(true);
        const payload = {};
        const isHitDummyURL = false;
        new omsDownloadService(payload, isHitDummyURL)
            .omsDownloadList(
                currentPage,
                search.trim(),
                dateRang,
                toParam,
                fromParam,
                shipDate,
                statusCode,
                limit
            )
            .then((res) => {
                if (res?.pagination?.totalCount >= 0) {
                    setSkeleton(false);
                }
                setShippedData(res.omsShippedLogs);
                dispatch(commonActions.SAVE_ORDER_DATA(res?.omsShippedLogs));
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                dispatch(commonActions.IS_DOWNLOAD({currentPage: currentPage, limit: limit}));
                setTotalPages(res?.pagination?.totalPages);
                setLimit(limit);
            })
            .catch((err: any) => {
                setCurrentPage(firstPage);
                dispatch(commonActions.IS_DOWNLOAD({currentPage: firstPage, limit: limit}));
                showSnackbar(err?.message, false);
            });
    };

    const handleSelectRange = (event: SelectChangeEvent) => {
        setFilterChange(true);
        setChecked(new Array(checked.length).fill(false));
        if (event.target.value == "custom") {
            setViewCustom(true);
        } else {
            setViewCustom(false);
            setFromParam("");
            setToParam("");
            setValueFrom(null);
            setValueTo(null);
            setDateRang(event.target.value);
        }
    };

    const handleSelectShipDate = (event: SelectChangeEvent) => {
        setFilterChange(true);
        setShipDate(event.target.value);
    };

    const handleSelectOption = (event: SelectChangeEvent) => {
        setStatusAll(event.target.value);
        setFilterChange(true);
        setStatusCode(event.target.value);
    };

    const handleSearchChange = (e: any) => {
        setFilterChange(true);
        setChecked(new Array(checked.length).fill(false));
        const { value } = e.target;
        setSearch(value);
    };

    const handleDateTo = (value: any) => {
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setToParam(`${year}/${month}/${day}`);
        setValueTo(value);
    };
    const handleDateFrom = (value: any) => {
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setFromParam(`${year}/${month}/${day}`);
        setValueFrom(value);
    };
    const handleSubmit = () => {
        if (validator.current.allValid()) {
            setDateRang("");
            if (dateRang == "") {
                const data = {
                    search,
                    shipDate,
                    dateRang,
                    fromParam,
                    toParam,
                    viewCustom,
                    valueFrom,
                    valueTo,
                    statusCode
                };
                dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER(data));
                omsShippedData(currentPage, limit);
            }
        } else {
            validator.current.showMessages();
        }
    };
    useEffect(() => {
        const data = {
            search,
            shipDate,
            statusCode,
            dateRang,
            fromParam,
            toParam,
            viewCustom,
            valueFrom,
            valueTo,
        };
        dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER(data));
   
    }, [search, shipDate, dateRang, statusCode]);

    const resetAllFilter = () => {
        setFilterChange(true);
        setViewCustom(false);
        setFromParam("");
        setToParam("");
        setValueFrom(null);
        setValueTo(null);
        setDateRang("7");
        setShipDate("AESC");
        setSearch("");
        setStatusCode("");
        setStatusAll("null");
    };
    const shouldDisableDateTo = (date: any) => {
        if (valueFrom) {
            return date < valueFrom;
        }
        return false;
    };
    const shouldDisableDateFrom = (date: any) => {
        if (valueTo) {
            return date > valueTo;
        }
        return false;
    };

    const CsvResponse = () => {
        showSnackbar(t("bulk_product_index.download_started"), true);
        const payload = {};
        const isHitDummyURL = false;
        setLoading(true);
        setSuccess(false);
        new omsDownloadService(payload, isHitDummyURL)
            .dowloadCsv(
                search.trim(),
                dateRang,
                toParam,
                fromParam,
                shipDate,
                statusCode,
                limit
            )
            .then((res: any) => {
                handleOnExport(res?.omsShippedLogs);
                setSkeleton(false);
                setLoading(false);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
                setSkeleton(false), setLoading(false);
            });
    };

    const handleOnExport = (downloadArray?: any) => {
        const downloadData: any = [];
        downloadArray?.map((item: any) => {
            item?.products.map((product: any) => {
                downloadData.push({
                    "注文日時": item?.orderDate?.split("T")[0],
                    "注文番号": item?.orderId,
                    "ステータス": (item?.status == "3")? OMSConfig.STATUS_CODE_3 : (item?.status == "5")? OMSConfig.STATUS_CODE_5 : (item?.status == "6") ? OMSConfig.STATUS_CODE_6 : OMSConfig.STATUS_CODE_9,
                    "キャンセル日時": item?.cancellationDateTime,
                    "出荷完了日時": item?.shipmentCompletionDate,
                    // "注文者メールアドレス": item?.ordererEmail,
                    "注文者氏名(姓)": item?.ordererName,
                    "注文者氏名(名)": item?.nameOfOrderer,
                    "注文者カナ(セイ)": item?.ordererKanaSei,
                    "注文者カナ(メイ)": item?.oredereKanaMei,
                    "商品コード": product?.productCode,
                    "カインズ商品コード": product?.cainzProductCode,
                    "商品名": product?.productName,
                    "販売数量": product?.salesVolume,
                    "出荷済数量": utility.getQuantitySum(product?.shippedQuantity),
                    // "出荷済数量": totalshippedQuantity,
                    "配送先氏名(姓)": item?.shippingSurname,
                    "配送先氏名(名)": item?.shippingFirstName,
                    "配送先カナ(セイ)": item?.shippingAddressKanaSei,
                    "配送先カナ(メイ)": item?.shippingAddressKanaMei,
                    "配送先郵便番号": item?.shippingPostalCode,
                    "配送先都道府県": item?.shippingPrefecture,
                    "配送先住所１": item?.shippingAddress1,
                    "配送先住所２": item?.shippingAddress2,
                    "配送先住所３": item?.shippingAddress3,
                    "配送先会社名": item?.shippingCompanyName,
                    "配送先部署名": item?.shippingDepartmentName,
                    "配送先電話番号": item?.shippingPhoneNumber,
                });
            });
        });
        const fileName = `注文一覧_${utility.getCurrentTimestamp()}.xlsx`;
        const numFmt="text";
        downloadDataToExcel(downloadData, fileName, SHEET_NAME.OMS_DOWNLOAD, numFmt);
        setSuccess(true);
        setLoading(false);
    };

    return (
        <>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Box className="oms_unshipped_filter">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2} sx={{ py: 1, px: 2 }}>
                                    <Typography className="oms-shipped-text">
                                        <Box component="span" className="oms_text_margin">
                                            {totalCount}
                                        </Box> 
                                        {t("oms.order_in_last")} {dateRang} {t("oms.days")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <TextField
                                        label={t("oms.search_by_order_id")}
                                        onChange={handleSearchChange}
                                        value={search}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel id="demo-simple-select-helper-label">
                                            {t("oms.date_range")}
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            className="oms-tab-filters"
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={viewCustom ? "custom" : dateRang}
                                            label={t("oms.date_range")}
                                            onChange={handleSelectRange}>
                                            <MenuItem value={7}>{t("oms.last_7_days")}</MenuItem>
                                            <MenuItem value={30}>{t("oms.last_30_days")}</MenuItem>
                                            <MenuItem value={90}>{t("oms.Last_90_days")}</MenuItem>
                                            <MenuItem value={365}>{t("oms.last_year")}</MenuItem>
                                            <MenuItem value={"custom"}>
                                                {t("oms.custom_date_range")}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={2}>
                                    <Box>
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="demo-simple-select-helper-label">
                                                {t("oms.status")}
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                className="oms-tab-filters"
                                                labelId="demo-simple"
                                                id="demo-simple"
                                                value={statusAll}
                                                label={t("oms.status")}
                                                onChange={handleSelectOption}>
                                                    
                                                <MenuItem value="3">{t("oms.backlog")}</MenuItem>
                                                <MenuItem value="5">
                                                    {t("oms.shipping_preparation")}
                                                </MenuItem>
                                                <MenuItem value="6">{t("oms.option_shipped")}</MenuItem>
                                                <MenuItem value="9">
                                                    {t("oms.option_canceled")}
                                                </MenuItem>
                                                <MenuItem value="null">
                                                    {t("oms.all")}
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <Box className="display_flex">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="demo-simple-select-helper-label">
                                                {t("oms.order_date")}
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                className="oms-tab-filters"
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={shipDate}
                                                label={t("oms.order_date")}
                                                onChange={handleSelectShipDate}>
                                                <MenuItem value="DESC">{t("oms.descending")}</MenuItem>
                                                <MenuItem value="AESC">{t("oms.ascending")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                          
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={4}
                                    xl={2}>
                                    <Grid container sx={{ position: "relative"}}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Button
                                                variant="contained"
                                                sx={{ textTransform: "capitalize"}}
                                                onClick={resetAllFilter}
                                                className="oms_reset_btn"
                                            >
                                                {t("pr.reset")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <Box sx={{textAlign:"center"}}>
                                                <DownloadButton loading={loading}  route={window.location.pathname} functionToExecute={() => CsvResponse()} className="download_button" btnName= {t("reports.download")} disableCondition={totalCount > OMSConfig.OMS_DOWNLOAD_LIMIT || loading || shippedData?.length == 0}/>
                                                {/* <Button
                                                    sx={{textTransform: "capitalize",}}
                                                    variant="contained"
                                                    className="download_button"
                                                    onClick={() => CsvResponse()}
                                                    disabled={totalCount > OMSConfig.OMS_DOWNLOAD_LIMIT || loading || shippedData?.length == 0}>
                                                    {t("reports.download")}
                                                </Button>                                       
                                                {loading && (
                                                    <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            position:"absolute",
                                                            top: "21%",
                                                            left: "67%",
                                                        }}
                                                    />
                                                )} */}
                                                {totalCount > OMSConfig.OMS_DOWNLOAD_LIMIT && <Typography> <Typography component="span"><CancelOutlinedIcon className="cross_icon"/></Typography>{t("oms.omsdownload_limit_msg")}</Typography>}   
                                            </Box>
                                        </Grid>
                                        
                                    </Grid> 
                                </Grid>
                                {viewCustom ? (
                                    <>
                                        <Grid item xs={0} sm={0} md={1} lg={4}></Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={4}
                                            lg={6}
                                            className="oms-date-picker"
                                        >
                                            <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                                dateFormats={{ monthAndYear: "yyyy年MM月" }}
                                                adapterLocale={jaLocale}
                                            >
                                                <Grid className="oms_date_picker_grid">
                                                    <DatePicker
                                                        label={t("pr.from")}
                                                        value={valueFrom}
                                                        inputFormat="yyyy/MM/dd"
                                                        onChange={handleDateFrom}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    readOnly: true,
                                                                    style: { pointerEvents: "none" },
                                                                }}
                                                            />
                                                        )}
                                                        autoFocus={true}
                                                        disableFuture
                                                        shouldDisableDate={shouldDisableDateFrom}
                                                        showToolbar={false}
                                                        componentsProps={{
                                                            actionBar: {
                                                                // The actions will be the same between desktop and mobile
                                                                actions: [],
                                                            },
                                                        }}
                                                    />

                                                    {validator.current.message("from", valueFrom, [
                                                        "required",
                                                    ])}
                                                </Grid>
                                            </LocalizationProvider>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                                adapterLocale={jaLocale}
                                                dateFormats={{ monthAndYear: "yyyy年MM月" }}
                                            >
                                                <Grid className="oms_date_picker_grid">
                                                    <DatePicker
                                                        className="oms-date-picker-default"
                                                        label={t("pr.to")}
                                                        value={valueTo}
                                                        inputFormat="yyyy/MM/dd"
                                                        onChange={handleDateTo}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    readOnly: true,
                                                                    style: { pointerEvents: "none" },
                                                                }}
                                                            />
                                                        )}
                                                        autoFocus={true}
                                                        disableFuture
                                                        shouldDisableDate={shouldDisableDateTo}
                                                        showToolbar={false}
                                                        componentsProps={{
                                                            actionBar: {
                                                                actions: [],
                                                            },
                                                        }}
                                                    />
                                                    {validator.current.message("to", valueTo, [
                                                        "required",
                                                    ])}
                                                </Grid>
                                            </LocalizationProvider>
                                            <Button
                                                variant="contained"
                                                className="oms_submit-btn"
                                                sx={{ textTransform: "capitalize" }}
                                                onClick={handleSubmit}
                                            >
                                                {t("pr.submit")}
                                            </Button>
                                        </Grid>
                                    </>
                                ) : null}
                            </Grid>
                        </Box>
                        
                    </Grid>
                </Grid>
            </Container>

            <Container className="oms_table">
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {skeleton ? (
                            <Skeleton
                                varient="rectangular"
                                width={0}
                                height={253}
                                count="10"
                            />
                        ) : (
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" sx={{ my: 4 }}>
                                    <TableHead className="oms_head">
                                        <TableRow>
                                            <TableCell>
                                                <Box sx={{ my: 1 }}> {t("oms.order_date")} </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.order_details")}</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.product_information")} </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.customer_option")}</Box>
                                            </TableCell>
                                            <TableCell className="order_status">
                                                <Box>{t("oms.order_status")}</Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {shippedData?.map((item: any, idIndex: number) => {
                                            const products = item?.products;
                                            orderIdCompare.push(item.orderId);
                                            return (
                                                <TableRow key={idIndex}>
                                                    <TableCell>
                                                        <Box className="oms_table_box">
                                                            <Typography
                                                                className={"order_date_checkbox " + idIndex}
                                                            >
                                                                {item?.orderDate}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell colSpan={5}>
                                                        <Table>
                                                            <TableBody>
                                                                <TableRow className="CEC-border-none">
                                                                    <TableCell>
                                                                        <Box className="oms_table_box">
                                                                            <Typography>
                                                                                {t("oms.order_id")}:
                                                                            </Typography>
                                                                            <Typography className="text_wrap_data">
                                                                                <Link
                                                                                    to={`${ROUTES.OMS_CONFIRM_SHIPMENT}/${item?.orderId}`}
                                                                                    className="Order_id_link"
                                                                                >
                                                                                    {item?.orderId}
                                                                                </Link>
                                                                            </Typography>
                                                                            
                                                                            <Typography>
                                                                                {t("oms.buyer_name")} :
                                                                            </Typography>

                                                                            <Typography className="text_wrap_data">
                                                                                {item?.ordererName}  {item?.nameOfOrderer}
                                                                            </Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Table>
                                                                            <TableBody>
                                                                                {products?.map(
                                                                                    (product: any, productIndex: any) => (
                                                                                        <TableRow
                                                                                            key={productIndex}
                                                                                            className="table_border"
                                                                                        >
                                                                                            <TableCell className="table_cell_padding">
                                                                                                <Box className="oms_table_box">
                                                                                                    <img
                                                                                                        src={
                                                                                                            product?.image
                                                                                                        }
                                                                                                        alt=""
                                                                                                    />
                                                                                                    <Typography className="text_wrap_data">
                                                                                                        {
                                                                                                            product?.productName
                                                                                                        }
                                                                                                    </Typography>

                                                                                                    <Typography>
                                                                                                        {t("oms.cpid")} : { product?.cainzProductCode}
                                                                                                    </Typography>
                                                                                                
                                                                                                                    
                                                                                                    <Typography>
                                                                                                        {t("oms.quantity")} : { product?.salesVolume}
                                                                                                        
                                                                                                   
                                                                                                    </Typography>
                                                                                                    <Typography>
                                                                                                        {t("oms.shipped_quantity")} : {product?.shippedQuantity.split("|").filter((item: any) => item !== "0").length > 0 ? product?.shippedQuantity.split("|").filter((product: any) => product !== "0").join(",") : "0"}
                                                                                                    </Typography>

                                                                                            
                                                                                                    {
                                                                                                        productIndex+1 == products?.length ?
                                                                                                            "":
                                                                                                            <Divider sx={{mt:3}}/>
                                                                                                    }
                                                                                                </Box>
                                                                                            </TableCell>

                                                                                            <TableCell>
                                                                                                <Box className="oms_table_box">
                                                                                                    {item?.status == "3" ? (
                                                                                                        <Typography>
                                                                                                            {t(
                                                                                                                "oms.delivery_by_date"
                                                                                                            )}
                                                                                                            :
                                                                                                            <Typography>
                                                                                                                {
                                                                                                                    product?.productDeliveryDate
                                                                                                                }
                                                                                                            </Typography>
                                                                                                        </Typography>
                                                                                                    ) : item?.status == "5" ? (
                                                                                                        <Typography>
                                                                                                            {t(
                                                                                                                "oms.delivery_by_date"
                                                                                                            )}
                                                      :
                                                                                                            <Typography>
                                                                                                                {
                                                                                                                    product?.productDeliveryDate
                                                                                                                }
                                                                                                            </Typography>
                                                                                                        </Typography>
                                                                                                    ) : item?.status == "6"? (
                                                                                                        <Typography>
                                                                                                            {t(
                                                                                                                "oms.order_shipment_completion_date"
                                                                                                            )}
                                                                                                            :
                                                                                                            <Typography>
                                                                                                                {
                                                                                                                    item?.shipmentCompletionDate
                                                                                                                }
                                                                                                            </Typography>
                                                                                                        </Typography>
                                                                                                    ) : item?.status == "9" ? (
                                                                                                        <Typography>
                                                                                                            {t("oms.cancel_date")} : 
                                                                                                            <Typography>
                                                                                                                {
                                                                                                                    item?.cancellationDateTime
                                                                                                                }
                                                                                                            </Typography>
                                                                                                        </Typography>
                                                                                                    ) : null}
                                                                                                </Box>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                )}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        {item?.status == "3" ? (
                                                                            <Button
                                                                                className="unshipped order_status bg_danger no_cursor"
                                                                                variant="contained"
                                                                            >
                                                                                {t("oms.unshipped")}
                                                                            </Button>
                                                                        ) : item?.status == "5" ? (
                                                                            <Button variant="contained" className="unshipped text_transform no_cursor">
                                                                              
                                                                                {t("oms.preparing_for_shipment")}
                                                                            </Button>
                                                                        ) : item?.status == "6" ? (
                                                                            <Button variant="contained" className="unshipped text_transform no_cursor">
                                                                               
                                                                                {t("oms.shipped")}
                                                                            </Button>
                                                                        ) : item?.status == "9" ? (
                                                                            <Button
                                                                                variant="contained"
                                                                                className="unshipped order_status bg_danger no_cursor"
                                                                            >
                                                                                {t("oms.cancel")}
                                                                            </Button>
                                                                        ) : null}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <Typography align="center" variant="h6">
                            {shippedData?.length == 0 ? t("oms.no_order_found") : ""}
                        </Typography>
                    </Grid>
                </Grid>
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageChange={omsShippedData}
                    totalPage={totalPages}
                    rowsPerPage={limit}
                    rowsPerPageChange={setLimit}
                />
            </Container>
        </>
    );
};
export default OmsDownload;
