import { API } from "../../../config";
import { LIST_LIMIT } from "../../../constants/constants";
import { bulkProductTypeypeWithPaginationType } from "../../../interfaces/bulkProductListInterface";
import {OrderLevelReportType, OrderLevelReportTypeWithPaginationType} from "../../../interfaces/OrderLevelReportInterface";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class ReportService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async orderLevelReport(storeCode:any) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.ORDER_LEVEL_REPORT}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.ORDER_LEVEL_REPORT}?storeCode=`+storeCode,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {         
            const businessReport: OrderLevelReportType = response?.data?.data;
            return businessReport;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async productLevelReport(productCode:any) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.PRODUCT_LEVEL_REPORT}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.PRODUCT_LEVEL_REPORT}?productCode=${productCode}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {     
            const businessReport: OrderLevelReportType = response?.data?.data;
            return businessReport;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async productLevelReportTableData(from:any, to:any, page_id: number, search?: string, limit = LIST_LIMIT.BULK_PRODUCT_LIST, colFilter?:string ,direction?:string) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.PRODUCT_LEVEL_REPORT_TABLE}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.PRODUCT_LEVEL_REPORT_TABLE}?from=${from}&to=${to}&page=${page_id}&limit=${limit}&search=${search}&column=${colFilter}&order=${direction}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount } = response.data.data;
            const tableData: OrderLevelReportTypeWithPaginationType = {
                OrderLevelReportLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
            };
            return tableData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async downloadOrderRepoart(
        storeCode:any,
    
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.ORDER_LEVEL_REPORT}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.ORDER_LEVEL_REPORT}?download=true&storeCode=`+storeCode,
                this.payload,
                this.headers
            ).get();
      
        if (response?.data?.success) {         
            const businessReport: OrderLevelReportType = response?.data?.data;
            return businessReport;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async downloadProductReport(from:any, to:any, search:any) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.PRODUCT_LEVEL_REPORT_TABLE}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.PRODUCT_LEVEL_REPORT_TABLE}?from=${from}&to=${to}&download=true&search=${search}`,
                this.payload,
                this.headers
            ).get();
      
        if (response?.data?.success) {         
            const businessReport: OrderLevelReportType = response?.data?.data;
            return businessReport;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async productlistData(page_id: number, search?: string, limit = LIST_LIMIT.BULK_PRODUCT_LIST) {  
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.PRODUCT_LIST}`,
                this.payload,
                this.headers
            ).getNew()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.PRODUCT_LIST}?isProductCode=true&page=${page_id}&limit=${limit}&search=${search}`,
                this.payload,
                this.headers
            ).getNew();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount } =
        response.data.data;
            const bulkProductData: bulkProductTypeypeWithPaginationType = {
                bulkProductLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
            };
            return bulkProductData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

}

export default ReportService;