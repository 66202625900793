import React from "react";
import { Box, Button, Modal, ThemeProvider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../redux/hooks";
import { commonActions } from "../redux/features/common/commonSlice";
import theme from "../theme";

const ModalUtility = (props:any) => {
    const { msg , modalOpen , setModalOpen, isReloadNeeded, reload, keepTabOpen } = props;

    const[t] = useTranslation();
    const dispatch = useAppDispatch();

    const reloadRequired = isReloadNeeded ? isReloadNeeded : false;

    const closeModal = () =>{
        if(reloadRequired && keepTabOpen){
            window.location.reload();
        }
        else if(reloadRequired){
            window.close();
            window.location.reload();
        } else if(reload) {
            location.reload();
            // dispatch(commonActions.SAVE_TIMER_VALUE({minutes: imageUploadConfig.IMAGE_UPLOAD_TIMER_MIN, seconds: imageUploadConfig.IMAGE_UPLOAD_TIMER_SEC }));
            dispatch(commonActions.SAVE_TIMER_VALUE(null));
        } else {
            setModalOpen(false);
        }
    };
    
    return (
        <>
            <ThemeProvider theme={theme}>
                <Modal
                    open={modalOpen}
                    // onClose={closeModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="center-model"  sx={{outline : "none"}}>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{ fontSize: "15px" }}
                        >{msg}</Typography>                 
                        <Button sx={{ mt: 2 ,lineHeight: "0.75", minWidth: "1px",}} onClick={closeModal}>
                            {t("exhibition_user_list.ok")}
                        </Button> 
                    </Box>
                </Modal>
            </ThemeProvider>
        </>
    );
};

export default ModalUtility;