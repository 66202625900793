import { API } from "../../../config";
import PermissionInitialState from "../../../interfaces/permissionInterface";
import IdNameModel from "../../../models/Generic/IdNameModel";
import LoginModel from "../../../models/SellerLogin/LoginModel";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath,mockAPIPath} from "../config/apiPath";


class AuthService {

    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read(),"accept-language":process.env.REACT_APP_LANGUAGE };
    }

    async registerStepFirst(){
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.REGISTER_FIRST_STEP, this.payload).getNew() : 
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.REGISTER_FIRST_STEP}`, this.payload,{["accept-language"]: process.env.REACT_APP_LANGUAGE }).post();
        let userDetails: LoginModel = new LoginModel();
        if(response?.data?.success==true) {
            userDetails = LoginModel.parseJson(response?.data?.data);
            return userDetails;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }

    async registerStepSecond(){
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.REGISTER, this.payload).getNew() : 
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.REGISTER}`, this.payload,this.headers).post();
        let isStoreRegister: string;
        let userDetails: LoginModel = new LoginModel();
        if(response?.data?.success==true) {
            isStoreRegister = response?.data?.message;
            userDetails = LoginModel.parseJson(response?.data?.data);
            return {isStoreRegister, userDetails};
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }

    async login() {
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.LOGIN, this.payload).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.LOGIN}`, this.payload).post();
        let userDetails: LoginModel = new LoginModel();
        if(response?.data?.success==true) {
            userDetails = LoginModel.parseJson(response?.data?.data);
            return userDetails;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 

    }

    async logout() {
        await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.LOGOUT}`, {}, this.headers).post();
        new Cookies("access-token").delete();
        // new Cookies("isStoreCreated").delete();
    }

    async verifyEmail(){
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.VERIFY_EMAIL, this.payload).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.VERIFY_EMAIL}`, this.payload).post();
        let isEmailVerified: string;
        if(response?.data?.success==true) {
            isEmailVerified = response?.data?.message;
            return isEmailVerified;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }

    async getState(){
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.STATES_LIST, this.payload).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.STATES_LIST}`, this.payload).getNew();
        const stateList:IdNameModel[] = []; 
        if(response?.data?.success==true)
        {
            response?.data?.data.forEach((item: any) => {
                let state:IdNameModel = new IdNameModel();
                state = IdNameModel.parseJson(item);
                stateList.push(state);
            });
            
            return stateList;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }
    async getCity(){
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.CITY_LIST, this.payload).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.CITY_LIST}`, this.payload).post();
        const cityList:IdNameModel[] = []; 
        if(response?.data?.success==true)
        {
            response?.data?.data.forEach((item: any) => {
                let city:IdNameModel = new IdNameModel();
                city = IdNameModel.parseJson(item);
                cityList.push(city);
            });
            
            return cityList;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }
    async getBrands(){
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(mockAPIPath.BRAND_LIST, this.payload).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.BRAND_LIST}`, this.payload).getNew();
        const brandList:IdNameModel[] = []; 
        if(response?.data?.success==true)
        {
            response?.data?.data?.forEach((item: any) => {
                let brand:IdNameModel = new IdNameModel();
                brand = IdNameModel.parseJson(item);
                brandList.push(brand);
            });
            
            return brandList;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }

    async getRolePermissions() {
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ?
            await new AxiosUtils(mockAPIPath.ROLES_BASED_PERMISSION).get() : 
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.ROLES_BASED_PERMISSION}`, {}, this.headers).get();
        let sellerPermissions: PermissionInitialState;
        if(response?.data?.success==true) {
            sellerPermissions = response?.data?.data;
            return sellerPermissions;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }

    async newGetRolePermissions(roleId:any) {
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ?
            await new AxiosUtils(mockAPIPath.NEW_ROLES_BASED_PERMISSION).get() : 
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.NEW_ROLES_BASED_PERMISSION}/${roleId}`, {}, this.headers).get();
        let sellerPermissions: object;
        if(response?.data?.success==true) {
            sellerPermissions = response?.data?.data;
            return sellerPermissions;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }

}

export default AuthService;