import React, { useState, useMemo, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Brush, ResponsiveContainer } from "recharts";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useTranslation } from "react-i18next";
import { Box, Button, ButtonGroup, Checkbox, CircularProgress, Container, Divider, FormControlLabel, Grid, IconButton, InputAdornment, ListItem, ListItemText, Modal, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables"; 
import Utility from "../../utils/Utility";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import ReportService from "./services/reportService";
import downloadDataToExcel from "../../utils/ExcelDownloadUtility";
import { SHEET_NAME } from "../../constants/constants";
import DownloadButton from "../common/DownloadBtn";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Skeleton from "../common/Skeleton";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "../common/Pagination";
import lazyTypingUtils from "../../utils/lazyTypingUtils";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CloseIcon from "@mui/icons-material/Close";
import CommonButton from "../common/Button";

dayjs.extend(isBetween);

const ProductReport = (props: any) =>{
    const { showSnackbar } = props;
    const [data, setData] = useState<any>([]);
    const [finalData, setFinalData] = useState<any>([]);
    const [tableData, setTableData] = useState<any>([]);
    const [startDate, setStartDate] = useState(new Date("2020-01-01"));
    const [endDate, setEndDate] = useState(new Date());
    const [filterRange, setFilterRange] = useState("");
    const [filterView, setFilterView] = useState("days");
    const [startIndex, setStartIndex] = useState(0);
    const [highlightedPoints, setHighlightedPoints] = useState<number[]>([]);
    const [endIndex, setEndIndex] = useState(data ? data.length - 1 : 0);
    const [hoveredLine, setHoveredLine] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const utility = new Utility();
    const [isGraphVisible, setIsGraphVisible] = useState(true);
    const [skeleton, setSkeleton] = useState(true);
    const [products, setProducts] = useState<any[]>([]);
    const [productNames, setproductNames] = useState<any>([]);
    const [productCodes, setProductCodes] = React.useState<string>("");
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = useState<string>("");
    const [limit, setLimit] = useState(15);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [isSearchChange,setIsSearchChange]=useState<boolean>(false);
    const [searchTable, setSearchTable] = useState<string>("");
    const [limitTable, setLimitTable] = useState(15);
    const [currentPageTable, setCurrentPageTable] = useState(1);
    const [totalCountTable, setTotalCountTable] = useState(0);
    const [totalPagesTable, setTotalPagesTable] = useState(0);
    const [isSearchChangeTable,setIsSearchChangeTable]=useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [colFilter,setColFilter] =useState<string>("");
    const [direction,setDirection]=useState<string>("");
    const [colName,setColName]=useState<string>("");
    const firstPage = 1;
    const [activeSeries, setActiveSeries] = useState({
        product_refund_amount:false,
        product_return_rate:false,
        number_of_product_return:false,
        number_of_items_sold: false,
        product_sales_amount: true,
    });
    const tableKeys = [
        { key: "product_refund_amount", value: "Product refund amount(¥)", unit: "¥" },
        { key: "product_return_rate", value: "Product return rate(%)", unit: "%" },
        { key: "number_of_product_return", value: "Number of product return", unit: "" },
        { key: "number_of_items_sold", value: "Number of items sold", unit: "" },
        { key: "product_sales_amount", value: "Product sales amount(¥)", unit: "¥" },
    ];
    const [line1, setLine1] = useState<any>({ cainzProductCode: "", key: "product_sales_amount", value: "Product sales amount(¥)", unit: "¥" });
    const [line2, setLine2] = useState<any>({ cainzProductCode: "", key: "product_sales_amount", value: "Product sales amount(¥)", unit: "¥" });
    const [line3, setLine3] = useState<any>({ cainzProductCode: "", key: "product_sales_amount", value: "Product sales amount(¥)", unit: "¥" });
    const [line4, setLine4] = useState<any>({ cainzProductCode: "", key: "product_sales_amount", value: "Product sales amount(¥)", unit: "¥" });
    const [line5, setLine5] = useState<any>({ cainzProductCode: "", key: "product_sales_amount", value: "Product sales amount(¥)", unit: "¥" });
    const { t } = useTranslation();
    let apiData: any[][] = [];

    const productLevelReportApiCall = () => {
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);
        new ReportService(payload, isHitDummyURL)
            .productLevelReport(productCodes)
            .then((res: any) => {
                res ? setData(res) : setData([]);
                setFinalData(res);
                res && setStartDate(new Date(res[0].date));
                setSkeleton(false);
                setFilterView("days");
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };

    const productLevelReportTableData = (currentPageTable: number, limitTable: number) => {
        setIsSearchChangeTable(false);
        const payload = {};
        const isHitDummyURL = false;
        const from = startDate.toLocaleDateString("en-CA");
        const to = endDate.toLocaleDateString("en-CA");
        setSkeleton(true);
        new ReportService(payload, isHitDummyURL)
            .productLevelReportTableData(from, to, currentPageTable, searchTable.trim(), limitTable, colFilter, direction)
            .then((res: any) => {
                res ? setTableData(res?.OrderLevelReportLogs) : setTableData([]);
                setLimitTable(limitTable);
                setCurrentPageTable(currentPageTable);
                setTotalCountTable(res?.pagination?.totalCount);
                setTotalPagesTable(res?.pagination?.totalPages);
                setSkeleton(false);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };

    useEffect(() => {
        productLevelReportTableData(currentPageTable,limitTable);
    }, [startDate,endDate,colFilter,direction]);

    useEffect(() => {
        productData(currentPage,limit);
    }, []);

    const downloadReportData = () => {
        showSnackbar("Download started", true);
        setLoading(true);
        const payload = {};
        const isHitDummyURL = false;
        const from = startDate.toLocaleDateString("en-CA");
        const to = endDate.toLocaleDateString("en-CA");
        new ReportService(payload, isHitDummyURL)
            .downloadProductReport(from, to, searchTable.trim())
            .then((res: any) => {
                handleOnExport(res?.data);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setLoading(false);
            });
    };
    const handleOnExport = (downloadArray?: any) => {
        const downloadData: any = [];
        downloadArray?.forEach((item: any) => {
            downloadData.push({
                "Product Code": item?.product_code,
                "Cainz Product Code": item?.cainz_product_code,
                "Product Name": item?.product_name,
                "Product sales amount(¥)": item?.product_sales_amount,
                "Number of items sold": item?.number_of_items_sold,
                "Product refund amount(¥)": item?.product_refund_amount,
                "Number of product return": item?.number_of_product_return,
                "Product return rate(%)": item?.product_return_rate
            });
        });
        const fileName = "ストア売上一覧.xlsx";
        downloadDataToExcel(downloadData, fileName, SHEET_NAME.SALES_LIST);
        setLoading(false);
    };

    const handleSorting =(changedColumn:any)=>{
        if(colName!=changedColumn){
            setDirection("asc");

        }else{
            setDirection(direction=="asc"? "desc":"asc");
        }
        
        setColName(changedColumn);   
        const columnName=changedColumn == "Product Code" || changedColumn=="商品コード" ? "product_code":
            changedColumn == "Cainz Product Code" || changedColumn == "カインズ商品コード" ? "cainz_product_code":
                changedColumn == "Product Name" || changedColumn=="商品名" ? "product_name":
                    changedColumn == "Product sales amount(¥)" || changedColumn=="税込商品価格" ? "product_sales_amount":
                        changedColumn == "Number of items sold" || changedColumn=="個別送料" ? "number_of_items_sold":
                            changedColumn == "Product refund amount(¥)" || changedColumn=="公開開始日" ? "product_refund_amount":
                                changedColumn == "Number of product return" || changedColumn=="公開終了日" ? "number_of_product_return":
                                    changedColumn == "Product Return Rate(%)" || changedColumn=="最終反映実施日" ? "product_return_rate":
                                        "";            
        setColFilter(columnName);

    };

    const columns = [
        {
            name: "No.",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "Product Code",
            label: "product_code",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    console.log(data);
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="product_code" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton> 
                        </Box>
                    );
                },
            },
        },
        {
            name: "Cainz Product Code",
            label: "cainz_product_code",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="cainz_product_code" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton> 
                        </Box>
                    );
                },
            },
        },
        {
            name: "Product Name",
            label: "product_name",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="product_name" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton> 
                        </Box>
                    );
                },
            },
        },
        {
            name: "Product sales amount(¥)",
            label: "product_sales_amount",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="product_sales_amount" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>
                            <IconButton color="primary" size="small" onClick={() => handleLegendClick(filterdata)} >
                                {(data.label == line1.key || data.label == line2.key) ? <ShowChartIcon className="chartIconPosition" /> : <ShowChartIcon className="swapVertIcon" />}
                            </IconButton> 
                        </Box>
                    );
                },
            },
        },
        {
            name: "Number of items sold",
            label: "number_of_items_sold",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="number_of_items_sold" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>
                            <IconButton color="primary" size="small" onClick={() => handleLegendClick(filterdata)} >
                                {(data.label == line1.key || data.label == line2.key) ? <ShowChartIcon className="chartIconPosition" /> : <ShowChartIcon className="swapVertIcon" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: "Product refund amount(¥)",
            label: "product_refund_amount",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="product_refund_amount" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>
                            <IconButton color="primary" size="small" onClick={() => handleLegendClick(filterdata)} >
                                {(data.label == line1.key || data.label == line2.key) ? <ShowChartIcon className="chartIconPosition" /> : <ShowChartIcon className="swapVertIcon" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: "Number of product return",
            label: "number_of_product_return",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="number_of_product_return" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>
                            <IconButton color="primary" size="small" onClick={() => handleLegendClick(filterdata)} >
                                {(data.label == line1.key || data.label == line2.key) ? <ShowChartIcon className="chartIconPosition" /> : <ShowChartIcon className="swapVertIcon" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: "Product Return Rate(%)",
            label: "product_return_rate",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="product_return_rate" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>
                            <IconButton color="primary" size="small" onClick={() => handleLegendClick(filterdata)} >
                                {(data.label == line1.key || data.label == line2.key) ? <ShowChartIcon className="chartIconPosition" /> : <ShowChartIcon className="swapVertIcon" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
    ];
    const handleHighlightPoints = (range:any) => {
        setFilterView(range);
        if(productNames.length>0){
            const filterarr = groupSalesWithDetails(finalData, range);
            const datavalue:any = Object.values(filterarr);
            setStartDate(new Date(datavalue[0].date));
            const filteredIndices: number[] = [];
            datavalue.forEach((item: any, index: any) => {
                filteredIndices.push(index);
            });
            setHighlightedPoints(filteredIndices);
        }
    };

    const groupSalesWithDetails = (data: any, unit: any) => {
        const groupedData = data.reduce((acc: any, sale: any) => {
            let key = "";
            let startOfDate;
            if (unit === "week") {
                key = dayjs(sale.date).startOf("week").format("YYYY-MM-DD");
                startOfDate = dayjs(sale.date).startOf("week").format("YYYY/MM/DD"); 
            }else if (unit === "month") {
                key = dayjs(sale.date).format("YYYY-MM");
                startOfDate = dayjs(sale.date).startOf("month").format("YYYY/MM/DD");
            } else if (unit === "days") {
                key = dayjs(sale.date).format("YYYY-MM-DD");
                startOfDate = dayjs(sale.date).format("YYYY/MM/DD");
            }
    
            if (!acc[key]) {
                acc[key] = {
                    date: startOfDate,
                    seller_code: 0
                };
                productCodes.split(",").forEach((productCode:any)=>{
                    acc[key] = {
                        ...acc[key],
                        [`${productCode}_product_code`] : sale[`${productCode}_product_code`],
                        [`${productCode}_cainz_product_code`] : sale[`${productCode}_cainz_product_code`],
                        [`${productCode}_product_name`] : sale[`${productCode}_product_name`],
                        [`${productCode}_product_sales_amount`] : 0,
                        [`${productCode}_number_of_items_sold`] : 0,
                        [`${productCode}_product_refund_amount`] : 0,
                        [`${productCode}_number_of_product_return`] : 0,
                        [`${productCode}_product_return_rate`] : 0,
                        
                    };
                });
            }
            productCodes.split(",").forEach((productCode:any)=>{
                acc[key][`${productCode}_product_sales_amount`] += sale[`${productCode}_product_sales_amount`];
                acc[key][`${productCode}_number_of_items_sold`] += sale[`${productCode}_number_of_items_sold`];
                acc[key][`${productCode}_product_refund_amount`] += sale[`${productCode}_product_refund_amount`];
                acc[key][`${productCode}_number_of_product_return`] += sale[`${productCode}_number_of_product_return`];
                acc[key][`${productCode}_product_return_rate`] = (acc[key][`${productCode}_number_of_product_return`] / (acc[key][`${productCode}_number_of_items_sold`] || 1));
            });
            return acc;
        }, {});
        setData(Object.values(groupedData));
        return groupedData;
    };

    const renderCustomDots = (props: any) => {
        const { cx, cy, stroke, index } = props;
        if (highlightedPoints && highlightedPoints.length > 0 && cy && highlightedPoints.includes(index)) {
            return (
                <circle
                    key={`item-${index}`}
                    cx={cx}
                    cy={cy}
                    r={3}
                    stroke={stroke}
                    strokeWidth={5}
                />
            );
        }
        return <></>;
    };

    useEffect(() => {
        const allIndices = data && data.map((_: any, index: any) => index);
        setHighlightedPoints(allIndices);
    }, [data]);

    useEffect(() => {
        const startIdx = data.findIndex((item: any) => new Date(item.date) >= new Date(startDate));
        const endIdx = data.findIndex((item: any) => new Date(item.date) >= new Date(endDate));
        setStartIndex(startIdx >= 0 ? startIdx : 0);
        setEndIndex(endIdx >= 0 ? endIdx : data.length - 1);
    }, [startDate, endDate]);

    const handleStartDateChange = (date: any) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: any) => {
        setEndDate(date);
    };

    const handleFilterRangeChange = (range: any) => {
        setFilterRange(range);
        applyFilter(range);
    };

    const applyFilter = (range: any) => {
        const now = dayjs();
        switch (range) {
        case "7d":
            setStartDate(now.subtract(7, "day").toDate());
            setEndDate(now.toDate());
            break;
        case "1m":
            setStartDate(now.subtract(1, "month").toDate());
            setEndDate(now.toDate());
            break;
        case "3m":
            setStartDate(now.subtract(3, "month").toDate());
            setEndDate(now.toDate());
            break;
        case "6m":
            setStartDate(now.subtract(6, "month").toDate());
            setEndDate(now.toDate());
            break;
        case "1y":
            setStartDate(now.subtract(1, "year").toDate());
            setEndDate(now.toDate());
            break;
        case "2y":
            setStartDate(now.subtract(2, "year").toDate());
            setEndDate(now.toDate());
            break;
        default:
            setStartDate(now.subtract(3, "year").toDate());
            setEndDate(now.toDate());
            break;
        }
    };

    const filterDataByDateRange = (start: any, end: any) => {
        if (!start || !end) return data;
        const startDate = new Date(start);
        const endDate = new Date(end);
        const filtered = data.filter((d: any) => {
            const currentDate = new Date(d.date);
            return (currentDate >= startDate && currentDate <= endDate);
        });
        return filtered;
    };

    const filteredData = useMemo(
        () => filterDataByDateRange(startDate, endDate),
        [startDate, endDate]
    );

    const handleBrushChange = (e: any) => {
        const { startIndex, endIndex } = e || {};
        if (startIndex !== undefined && endIndex !== undefined) {
            const brushStartDate = data[startIndex]?.date;
            const brushEndDate = data[endIndex]?.date;
            // setStartDate(new Date(brushStartDate));
            // setEndDate(new Date(brushEndDate));
            filterDataByDateRange(new Date(brushStartDate), endDate);
        }
    };

    const handleLegendClick = (o: any) => {
        const { dataKey } = o;
        
        setActiveSeries((prevState: any) => {
            const selectedSeries = tableKeys.find((item) => item.key === dataKey);
            const newActiveSeries = {
                ...prevState,
                [dataKey]: !prevState[dataKey],
            };
            const codesArray = productCodes.split(",").map(code => code.trim());
            setLine1({...selectedSeries,cainzProductCode:codesArray[0] || ""});
            setLine2({...selectedSeries,cainzProductCode:codesArray[1] || ""});
            setLine3({...selectedSeries,cainzProductCode:codesArray[2] || ""});
            setLine4({...selectedSeries,cainzProductCode:codesArray[3] || ""});
            setLine5({...selectedSeries,cainzProductCode:codesArray[4] || ""});
            return newActiveSeries;
        });
    };

    const dayDifference = dayjs(filteredData[filteredData.length - 1]?.date).diff(dayjs(filteredData[0]?.date), "day");

    const tickFormatter = (date: any) => {
        if (dayDifference > 365) {
            return dayjs(date).format("YYYY"); // Format as full year
        } else if (dayDifference > 30) {
            return dayjs(date).format("MMM YYYY"); // Format as month and year
        } else {
            return dayjs(date).format("DD MMM"); // Format as day and month
        }
    };

    const numberWithCommas = (value: any) => {
        return (
            <Box sx={{ textAlign: "right" }}>{utility.numberWithCommas(value)}</Box>
        );
    };

    const apiTableData = () => {
        {
            apiData = [];
            tableData.length>0 && tableData?.map((item: any, index: number) => {
                apiData.push([
                    index+1,
                    <Box key={index} sx={{ textAlign: "right" }}>{item.product_code}</Box>,
                    <Box key={index} sx={{ textAlign: "right" }}>{item.cainz_product_code}</Box>,
                    <Box key={index} sx={{ textAlign: "right" }}>{item.product_name}</Box>,
                    numberWithCommas(item.product_sales_amount),
                    numberWithCommas(item.number_of_items_sold),
                    numberWithCommas(item.product_refund_amount),
                    numberWithCommas(item.number_of_product_return),
                    numberWithCommas(item.product_return_rate)
                ]);
            });
        }
    };
    apiTableData();

    const handleMouseEnter = (lineKey: any) => {
        setHoveredLine(lineKey);
    };
    const handleToggle = (dataKey: string) => {
        if (hoveredLine === dataKey) {
            handleMouseLeave();
            setHoveredLine("");
        } else {
            handleMouseEnter(dataKey);
            setHoveredLine(dataKey);
        }
    };

    const handleMouseLeave = () => {
        setHoveredLine("");
    };

    const CustomLegend = (props: any) => {
        const { payload } = props;
        return (
            <Box display="flex" alignItems="center" flexWrap="wrap" padding="10px">
                {payload.map((entry: any, index: any) => {
                    const dataKeyPrefix = entry.dataKey.split("_")[0];
                    const matchingProductCode = finalData.length>0 ? finalData[0][`${dataKeyPrefix}_product_code`] : null;
                    return (<Button key={`item-${index}`} variant="outlined" size="small"
                        sx={{ margin: "5px", borderColor: entry.color, color: entry.color, display: "flex", alignItems: "center", }}
                    >
                        <Box display="flex" alignItems="center" style={{ marginRight: "5px" }}>
                            <Box width="12px" height="12px" borderRadius="50%" bgcolor={entry.color} marginRight="8px"></Box>
                            <Typography variant="body2" onClick={() => handleToggle(entry.dataKey)}>{matchingProductCode}</Typography>
                        </Box>
                    </Button>);
                })}
            </Box>
        );
    };
    const handleSwitchChange = (event: any) => {
        setIsGraphVisible(event.target.checked);
    };

    const productData = (currentPage: number, limit: number) => {
        setIsSearchChange(false);
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);
        new ReportService(payload, isHitDummyURL)
            .productlistData(currentPage, search.trim(), limit)
            .then((res) => {
                const extractedData = res.bulkProductLogs.map((item:any) => ({
                    cainzProductCode: item.cainzProductCode,
                    productName: item.productName,
                    productCode: item.productCode
                }));
                setProducts(extractedData);
                setLimit(limit);
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setSkeleton(false);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };

    const handleUpdateProducts = () => {
        productLevelReportApiCall();
    };

    const updateLines = (codes: string[]) => {
        const [code1, code2, code3, code4, code5] = codes;
      
        setLine1((prev:any) => ({ ...prev, cainzProductCode: code1 || "" }));
        setLine2((prev:any) => ({ ...prev, cainzProductCode: code2 || "" }));
        setLine3((prev:any) => ({ ...prev, cainzProductCode: code3 || "" }));
        setLine4((prev:any) => ({ ...prev, cainzProductCode: code4 || "" }));
        setLine5((prev:any) => ({ ...prev, cainzProductCode: code5 || "" }));
    };
    
    const handleSelectProducts = (event: React.ChangeEvent<HTMLInputElement>, productName: string, productCode: string) => {
        let newProductNames = [...productNames];
        let newProductCodes = productCodes ? productCodes.split(",") : [];

        if (event.target.checked) {
            if (newProductNames.length < 5) {
                newProductNames.push(productName);
                newProductCodes.push(productCode);
            }
        } else {
            newProductNames = newProductNames.filter((name) => name !== productName);
            newProductCodes = newProductCodes.filter((code) => code !== productCode);
        }
      
        setproductNames(newProductNames);
        setProductCodes(newProductCodes.join(","));
        updateLines(newProductCodes);
    };

    const handleRemoveProduct = (productName: string, productCode: string) => {
        const newProductNames = productNames.filter((name:any) => name !== productName);
        const newProductCodes = productCodes.split(",").filter((code) => code !== productCode);
    
        setproductNames(newProductNames);
        setProductCodes(newProductCodes.join(","));
        updateLines(newProductCodes);
    };

    const handleOpenModal = () => {
        setOpen(true);
    };
    
    const handleCloseModal = () => {
        setOpen(false);
    };
    const handleSearchChange = (e: any) => {
        setIsSearchChange(true);
        const { value } = e.target;
        setSearch(value);
    };
    const handleKeyPress = (e: any) => {
        if (e.keyCode == 13) {
            productData(currentPage,limit);
        }
    };
    const onsearchApicall = () =>{
        if(isSearchChange){
            productData(firstPage, limit);
        }

    };
    const propItem = [
        onsearchApicall,search
    ];
    lazyTypingUtils(propItem);

    const handleSearchChangeTable = (e: any) => {
        setIsSearchChangeTable(true);
        const { value } = e.target;
        setSearchTable(value);
    };
    const handleKeyPressTable = (e: any) => {
        if (e.keyCode == 13) {
            productLevelReportTableData(currentPageTable,limitTable);
        }
    };
    const onsearchApicallTable = () =>{
        if(isSearchChangeTable){
            productLevelReportTableData(firstPage, limitTable);
        }

    };
    const propItemTable = [
        onsearchApicallTable,searchTable
    ];
    lazyTypingUtils(propItemTable);

    const handleRowClick = (index: number) => {
        const selectedIndex = selectedRows.indexOf(index);
        let newSelectedRows: number[] = [];
    
        if (selectedIndex === -1) {
            newSelectedRows = newSelectedRows.concat(selectedRows, index);
        } else {
            newSelectedRows = newSelectedRows.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }
    
        if (newSelectedRows.length <= 5) {
            setSelectedRows(newSelectedRows);
        }
    };

    const isSelected = (index: number) => selectedRows.indexOf(index) !== -1;

    return (
        <>

            <Container sx={{maxWidth: "1170px"}}>
                <Grid container mt={4}>
                    <Grid item xs={6} sm={6} md={6} lg={9} xl={6}>
                        <Typography variant="h5">{"Product Level Business Report"}</Typography>
                        <Typography
                            sx={{ borderBottom: "3px solid red", width: "50px" }}
                        ></Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={6}>
                        <Switch  className = "float_right" checked={isGraphVisible} onChange={handleSwitchChange} />
                    </Grid>
                </Grid>

                <Grid container mt={4}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={4} >
                        <Typography  >{"View By"}</Typography>
                        <ButtonGroup variant="outlined" aria-label="Basic button group" >
                            <Button variant={filterView == "days" ? "contained" : "outlined"} onClick={()=>handleHighlightPoints("days")}>{"Days"}</Button>
                            <Button variant={filterView == "week" ? "contained" : "outlined"} onClick={()=>handleHighlightPoints("week")}>{"Week"}</Button>
                            <Button variant={filterView == "month" ? "contained" : "outlined"} onClick={()=>handleHighlightPoints("month")}>{"Month"}</Button>
                        </ButtonGroup>
                    </Grid>
                    
                </Grid>
            </Container>


            <Container>
                {skeleton ? (
                    <Skeleton
                        varient="rectangular"
                        width={0}
                        height={60}
                        count="10"
                        my={0}
                    />
                ) : (
                    isGraphVisible && (
                        <Box>
                            <ResponsiveContainer width="98%" height={500}>
                                <LineChart
                                    data={filteredData}
                                    syncId="mainChart"
                                    margin={{ top: 10, right: 30, left: 40, bottom: 5 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date"
                                        tickFormatter={tickFormatter}
                                        tick={{ fontSize: 12, fill: "#666" }}
                                        interval={
                                            dayDifference > 365 ? 365 :   
                                                dayDifference > 30 ? 30 : 0   
                                        }
                                        minTickGap={50}
                                        allowDuplicatedCategory={false}
                                    />
                                    <YAxis
                                        tickMargin={2}
                                        label={{
                                            value: `${line1.value}`,
                                            offset: -20,
                                            angle: -90,
                                            position: "insideLeft",
                                            style: { fill: "#8884d8" }
                                        }}
                                        tick={{ fill: "#8884d8" }}
                                        axisLine={{ stroke: "#8884d8" }}
                                        tickFormatter={(value: any) => line1.unit == "¥" ? `${line1.unit} ${utility.numberWithCommas(value)}` : `${utility.numberWithCommas(value)} ${line1.unit}`}
                                    />
                                    <Tooltip
                                        formatter={(value: any, name: any, props: any) => {
                                            const cainzProductCode = props.dataKey.split("_")[0];
                                            const matchingProductCode = props?.payload[`${cainzProductCode}_product_code`];
                                            if (name === line1?.value) {
                                                return [line1.unit === "¥" ? ` ${line1.unit}${utility.numberWithCommas(String(value))}` : ` ${utility.numberWithCommas(String(value))}${line1.unit}`, matchingProductCode];
                                            }
                                            return matchingProductCode;
                                        }}
                                    />
                                    {finalData.length > 0 && <Legend
                                        content={CustomLegend}
                                        layout="horizontal"
                                        verticalAlign="top"
                                        align="left"
                                        // wrapperStyle={{ top:10, left:20, right:12 }}
                                        height={80}
                                        // width={13}
                                        onClick={() => handleMouseEnter(`${line1?.cainzProductCode}_${line1?.key}`)}
                                        iconSize={19}
                                        chartHeight={11}
                                        chartWidth={12}
                                        iconType="circle"
                                    />}
                                    {line1?.cainzProductCode && <Line
                                        key={`${line1?.cainzProductCode}_${line1?.key}`}
                                        type="monotone"
                                        dataKey={`${line1?.cainzProductCode}_${line1?.key}`}
                                        stroke={hoveredLine === `${line1?.cainzProductCode}_${line1?.key}` ? "#8884d8" : (hoveredLine ? "#d3d3d3" : "#8884d8")}
                                        // opacity={hoveredLine === `${line1?.cainzProductCode}_${line1?.key}` ? 1 : 0.7}
                                        activeDot={{ r: 8 }}
                                        // hide={!`${line1?.cainzProductCode}_${line1?.key}`}
                                        strokeWidth={3}
                                        dot={renderCustomDots}
                                        // width={9000}
                                        // height={500}
                                        name={line1?.value}
                                        onMouseEnter={() => handleMouseEnter(`${line1?.cainzProductCode}_${line1?.key}`)}
                                        onMouseLeave={handleMouseLeave}
                                        cursor="pointer"
                                        connectNulls={true}
                                    />}
                                    {line2?.cainzProductCode && <Line
                                        key={`${line2?.cainzProductCode}_${line2?.key}`}
                                        type="monotone"
                                        dataKey={`${line2?.cainzProductCode}_${line2?.key}`}
                                        stroke={hoveredLine === `${line2?.cainzProductCode}_${line2?.key}` ? "#82ca9d" : (hoveredLine ? "#d3d3d3" : "#82ca9d")} // Highlight or grey out based on hover
                                        activeDot={{ r: 8 }}
                                        // hide={!`${line2?.cainzProductCode}_${line2?.key}`}
                                        strokeWidth={3}
                                        dot={renderCustomDots}
                                        name={line2?.value}
                                        onMouseEnter={() => handleMouseEnter(`${line2?.cainzProductCode}_${line2?.key}`)}
                                        onMouseLeave={handleMouseLeave}
                                        cursor="pointer"
                                        connectNulls={true}
                                    />}
                                    {line3?.cainzProductCode && <Line
                                        key={`${line3?.cainzProductCode}_${line3?.key}`}
                                        type="monotone"
                                        dataKey={`${line3?.cainzProductCode}_${line3?.key}`}
                                        stroke={hoveredLine === `${line3?.cainzProductCode}_${line3?.key}` ? "#f32c02" : (hoveredLine ? "#d3d3d3" : "#f32c02")} // Highlight or grey out based on hover
                                        hide={!`${line3?.cainzProductCode}_${line3?.key}`}
                                        strokeWidth={3}
                                        dot={renderCustomDots}
                                        name={line3?.value}
                                        onMouseEnter={() => handleMouseEnter(`${line3?.cainzProductCode}_${line3?.key}`)}
                                        onMouseLeave={handleMouseLeave}
                                        cursor="pointer"
                                        connectNulls={true}
                                    />}
                                    {line4?.cainzProductCode && <Line
                                        key={`${line4?.cainzProductCode}_${line4?.key}`}
                                        type="monotone"
                                        dataKey={`${line4?.cainzProductCode}_${line4?.key}`}
                                        stroke={hoveredLine === `${line4?.cainzProductCode}_${line4?.key}` ? "#dbc916" : (hoveredLine ? "#d3d3d3" : "#dbc916")} // Highlight or grey out based on hover
                                        hide={!`${line4?.cainzProductCode}_${line4?.key}`}
                                        strokeWidth={3}
                                        dot={renderCustomDots}
                                        name={line4?.value}
                                        onMouseEnter={() => handleMouseEnter(`${line4?.cainzProductCode}_${line4?.key}`)}
                                        onMouseLeave={handleMouseLeave}
                                        cursor="pointer"
                                        connectNulls={true}
                                    />}
                                    {line5?.cainzProductCode && <Line
                                        key={`${line5?.cainzProductCode}_${line5?.key}`}
                                        type="monotone"
                                        dataKey={`${line5?.cainzProductCode}_${line5?.key}`}
                                        stroke={hoveredLine === `${line5?.cainzProductCode}_${line5?.key}` ? "#02d5f3" : (hoveredLine ? "#d3d3d3" : "#02d5f3")} // Highlight or grey out based on hover
                                        hide={!`${line5?.cainzProductCode}_${line5?.key}`}
                                        strokeWidth={3}
                                        dot={renderCustomDots}
                                        name={line5?.value}
                                        onMouseEnter={() => handleMouseEnter(`${line5?.cainzProductCode}_${line5?.key}`)}
                                        onMouseLeave={handleMouseLeave}
                                        cursor="pointer"
                                        connectNulls={true}
                                    />}
                                    {!line1?.cainzProductCode && !line2?.cainzProductCode && !line3?.cainzProductCode && !line4?.cainzProductCode && !line5?.cainzProductCode && (
                                        <text
                                            x="50%"
                                            y="50%"
                                            textAnchor="middle"
                                            dominantBaseline="middle"
                                            style={{ fontSize: "16px", fill: "#888" }}
                                        >
                                            {" No product selected"}
                                        </text>
                                    )}
                                </LineChart>
                            </ResponsiveContainer>

                            <ResponsiveContainer width="96%" height={90}>
                                <LineChart
                                    data={data}
                                    margin={{ top: 0, right: 0, left: 50, bottom: 0 }}
                                >
                                    <YAxis yAxisId="left" orientation="left" hide={true} />
                                    <XAxis dataKey="date"
                                        tick={{ fontSize: 12, fill: "#666" }}
                                        allowDuplicatedCategory={false}
                                    />
                                    {line1?.cainzProductCode && <Line
                                        type="monotone"
                                        dataKey={`${line1?.cainzProductCode}_${line1?.key}`}
                                        stroke="#8884d8"
                                        dot={false}
                                        className="line-chart"
                                        yAxisId="left"
                                        connectNulls={true}
                                    />}
                                    {line2?.cainzProductCode && <Line
                                        className="line-chart"
                                        type="monotone"
                                        dataKey={`${line2?.cainzProductCode}_${line2?.key}`}
                                        stroke="#82ca9d"
                                        dot={false}
                                        yAxisId="left"
                                        connectNulls={true}
                                    />}
                                    {line3?.cainzProductCode && <Line
                                        type="monotone"
                                        dataKey={`${line3?.cainzProductCode}_${line3?.key}`}
                                        stroke="#f32c02"
                                        dot={false}
                                        className="line-chart"
                                        yAxisId="left"
                                        connectNulls={true}
                                    />}
                                    {line4?.cainzProductCode && <Line
                                        className="line-chart"
                                        type="monotone"
                                        dataKey={`${line4?.cainzProductCode}_${line4?.key}`}
                                        stroke="#dbc916"
                                        dot={false}
                                        yAxisId="left"
                                        connectNulls={true}
                                    />}
                                    {line5?.cainzProductCode && <Line
                                        type="monotone"
                                        dataKey={`${line5?.cainzProductCode}_${line5?.key}`}
                                        stroke="#02d5f3"
                                        dot={false}
                                        className="line-chart"
                                        yAxisId="left"
                                        connectNulls={true}
                                    />}
                                </LineChart>
                            </ResponsiveContainer>

                            <div style={{ marginTop: 20 }} className="rechart">
                                <ResponsiveContainer width="96%" height={60}>
                                    <AreaChart
                                        data={data}
                                        syncId="mainChart"
                                        margin={{ top: 0, right: 40, left: 20, bottom: 0 }}
                                    >
                                        <YAxis yAxisId="left" orientation="left" hide={true} />
                                        <YAxis yAxisId="right" orientation="right" hide={true} />
                                        <XAxis dataKey="date" hide />

                                        <Brush
                                            dataKey="date"
                                            tickFormatter={tickFormatter}
                                            // tick={{ fontSize: 12, fill: "#666" }}
                                            data={data}
                                            startIndex={startIndex}
                                            endIndex={endIndex}
                                            height={30}
                                            stroke="#00684D"
                                            onDragEnd={handleBrushChange}
                                        >
                                            <XAxis
                                                dataKey="date"
                                                tick={{ fontSize: 10 }}
                                                tickLine={false}
                                                tickFormatter={(value: any) => String(new Date(value).getFullYear())}
                                            />
                                        </Brush>
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </Box>
                    )
                )}

                <Container className="chart_table" >
                    <Grid container>
                        <Grid item xs={12} sm={4} md={5} lg={6} xl={6} sx={{ float: "right"}}>
                            <Typography style={{  marginRight: 5 }} >{"Zoom"}</Typography>
                            <ButtonGroup variant="outlined" aria-label="Basic button group" >
                                <Button variant={filterRange == "7d" ? "contained" : "outlined"} onClick={() => handleFilterRangeChange("7d")}>{"7D"}</Button>
                                <Button variant={filterRange == "1m" ? "contained" : "outlined"} onClick={() => handleFilterRangeChange("1m")}>{"1M"}</Button>
                                <Button variant={filterRange == "3m" ? "contained" : "outlined"} onClick={() => handleFilterRangeChange("3m")}>{"3M"}</Button>
                                <Button variant={filterRange == "6m" ? "contained" : "outlined"} onClick={() => handleFilterRangeChange("6m")}>{"6M"}</Button>
                                <Button variant={filterRange == "1y" ? "contained" : "outlined"} onClick={() => handleFilterRangeChange("1y")}>{"1Y"}</Button>
                                <Button variant={filterRange == "2y" ? "contained" : "outlined"} onClick={() => handleFilterRangeChange("2y")}>{"2Y"}</Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ float: "right"}}>
                            <Grid container spacing={2} sx={{ marginTop: "6px" }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <DatePicker
                                            label="From"
                                            value={startDate}
                                            onChange={handleStartDateChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        ".MuiInputBase-root": {
                                                            height: "36px",
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={5} md={5} lg={6} xl={6}>
                                        <DatePicker
                                            label="To"
                                            value={endDate}
                                            onChange={handleEndDateChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        ".MuiInputBase-root": {
                                                            height: "36px",
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} sm={4} md={3} lg={2} xl={2} sx={{ float: "right", mt: "21px" }} >
                            <Button className="float_right" onClick={handleOpenModal} variant="contained">Select Products</Button>
                        </Grid>
                        <Modal open={open} onClose={handleCloseModal}>
                            <div
                                style={{
                                    padding: "20px",
                                    backgroundColor: "white",
                                    // margin: "100px auto",
                                    // width: "600px",
                                    height: "500px",
                                    overflowY: "auto",
                                    border: "2px solid white",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                }}
                                className="center-model"
                            >
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={8} lg={8} sx={{ py: 1}}>
                                        <TextField
                                            label={t("product_list.search_place_holder_product")}
                                            onChange={handleSearchChange}
                                            onKeyUp={handleKeyPress}
                                            value={search}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton>
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ float: "right", py: 1}} >
                                        <Button className="float_right" onClick={handleUpdateProducts} variant="contained">Update</Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 1}}>
                                        {productCodes && 
                                        productCodes.split(",").map((item:any,index:any)=>(
                                            <ListItem key={index} style={{
                                                backgroundColor: "#f9f9f9",
                                                borderRadius: "5px",
                                                margin: "4px 0",
                                                padding: "10px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                position: "relative"
                                            }}>
                                                {productNames[index]} ({item})
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={() => handleRemoveProduct(productNames[index], item)}
                                                    sx={{
                                                        position: "absolute",
                                                        right: "10px",
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </ListItem>
                                        ))}
                                    </Grid>
                                </Grid>
                                {skeleton ? (
                                    <CircularProgress />
                                ) : (
                                    <div>
                                        {products.map((product:any,index:any) => (
                                            <ListItem 
                                                key={product.cainzProductCode}
                                                style={{
                                                    backgroundColor: index % 2 === 0 ? "#e0e0e0" : "#f9f9f9",
                                                    borderRadius: "5px",
                                                    margin: "4px 0",
                                                    padding: "10px",
                                                }}
                                            >
                                                <FormControlLabel
                                                    key={product.cainzProductCode}
                                                    control={
                                                        <Checkbox
                                                            checked={productNames.includes(product.productName)}
                                                            onChange={(event) => handleSelectProducts(event, product.productName, product.cainzProductCode)}
                                                            disabled={
                                                                productNames.length >= 5 && !productNames.includes(product.productName)
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <ListItemText
                                                            primary={`${product.productName} (${product.cainzProductCode})`}
                                                        />
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </div>
                                )}
                                <Pagination
                                    totalCount={totalCount}
                                    currentPage={currentPage}
                                    pageChange={productData}
                                    totalPage={totalPages}
                                    rowsPerPage={limit}
                                    rowsPerPageChange={setLimit}
                                />
                            </div>
                        </Modal>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ float: "right", mt: 1 }} >
                            <DownloadButton loading={loading} route={window.location.pathname} className="float_right" functionToExecute={downloadReportData} btnName={t("pr.download")} disableCondition={loading} />
                        </Grid> */}
                        <Grid item xs={12} sm={4} md={3} lg={2} xl={2} sx={{ float: "right", mt: "21px" }} >
                            <DownloadButton loading={loading} route={window.location.pathname} className="float_right" functionToExecute={downloadReportData} btnName={t("pr.download")} disableCondition={loading} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8} md={4} lg={4} sx={{ mt:2, py: 1}}>
                            <TextField
                                label={t("product_list.search_place_holder_product")}
                                onChange={handleSearchChangeTable}
                                onKeyUp={handleKeyPressTable}
                                value={searchTable}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={8} lg={8} xl={8} sx={{ float: "right", mt:2, py: 1}} >
                            <Button className="float_right" onClick={handleUpdateProducts} variant="contained">Update</Button>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            {skeleton ? (
                                <Skeleton
                                    varient="rectangular"
                                    width={0}
                                    height={253}
                                    count="10"
                                />
                            ) : (
                                <Paper>
                                    <TableContainer className="productLevelCustomTableContainer">
                                        <Table className="productLevelCustomTable">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox"></TableCell>
                                                    {columns.map((column:any, index:any) => (
                                                        <TableCell key={index}>
                                                            {column.options.customHeadLabelRender
                                                                ? column.options.customHeadLabelRender(column)
                                                                : column.name}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableData?.map((row:any, index:any) => {
                                                    const isItemSelected = isSelected(index);
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    return (
                                                        <TableRow
                                                            hover
                                                            onClick={() => handleRowClick(index)}
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={index}
                                                            selected={isItemSelected}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={isItemSelected}
                                                                    onChange={(event) => handleSelectProducts(event, row?.product_name, row?.cainz_product_code)}
                                                                    inputProps={{ "aria-labelledby": labelId }}
                                                                    disabled={!isItemSelected && selectedRows.length >= 5}
                                                                />
                                                            </TableCell>
                                                            <TableCell>{(currentPageTable - 1) * limitTable + index + 1}</TableCell>
                                                            <TableCell>{row?.product_code}</TableCell>
                                                            <TableCell>{row?.cainz_product_code}</TableCell>
                                                            <TableCell>{row?.product_name}</TableCell>
                                                            <TableCell>{row?.product_sales_amount}</TableCell>
                                                            <TableCell>{row?.number_of_items_sold}</TableCell>
                                                            <TableCell>{row?.product_refund_amount}</TableCell>
                                                            <TableCell>{row?.number_of_product_return}</TableCell>
                                                            <TableCell>{row?.product_return_rate}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            )}
                            <Typography align="center" variant="h6">
                                {tableData?.length == 0 ? "No Data Found" : ""}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Pagination
                        totalCount={totalCountTable}
                        currentPage={currentPageTable}
                        pageChange={productLevelReportTableData}
                        totalPage={totalPages}
                        rowsPerPage={limitTable}
                        rowsPerPageChange={setLimitTable}
                    />
                    {/* <MUIDataTable
                        title={""}
                        columns={columns}
                        data={apiData}
                        options={{
                            pagination: true,
                            print: false,
                            download: false,
                            filter: false,
                            viewColumns: false,
                            search: false,
                            selectableRowsHideCheckboxes: false,
                            selectableRowsOnClick: true, // Enable row selection
                            onRowSelectionChange: handleRowSelectionChange, // Custom row selection logic
                            isRowSelectable: (dataIndex) => {
                                return selectedRows.length < 5 || selectedRows.includes(dataIndex);
                            },
                            textLabels: {
                                body: {
                                    noMatch: t("table_text.no_match").toString(),
                                },
                                viewColumns: {
                                    title: t("table_text.show_columns").toString(),
                                    titleAria: "Show/Hide Table Columns",
                                },
                                toolbar: {
                                    search: t("table_text.search").toString(),
                                    viewColumns: t("table_text.view_columns").toString(),
                                },
                            },
                            searchPlaceholder: t("table_text.search_placeholder").toString(),
                            responsive: "standard",
                        }} /> */}

                </Container>
            </Container>
        </>
    );
    
};
export default ProductReport;