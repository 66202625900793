import React from "react";
import { Container, Grid, Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TaskIcon from "@mui/icons-material/Task";
import HeaderTitle from "../common/HeaderTitle";
import TemplateDownload from "./TemplateDownload";
import FileUpload from "./FileUpload";
import Reports from "./Reports";
import { useTranslation } from "react-i18next";

const BulkProductUpload = (props: any) => {
    /* Language translate function*/
    const [t] = useTranslation();
    /* Language translate function*/
    const { handleLoader, showSnackbar } = props;
    const [value, setValue] = React.useState("1");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <div className="main">
                <Container sx={{ py: 2 }}>
                    {/* Page header title */}
                    <HeaderTitle title={t("bulk_product_index.bulk_product_upload")} />
                    {/* Page header title */}
                    <Box
                        sx={{
                            maxWidth: { xs: "600px", sm: "100%", md: "100%", lg: "100%" },
                        }}
                    >
                        <TabContext value={value}>
                            {/*Tab menu starts here*/}
                            <Container sx={{ py: 1 }}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box>
                                            <TabList
                                                onChange={handleChange}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="scrollable auto tabs example"
                                            >
                                                <Tab
                                                    icon={<CloudDownloadIcon />}
                                                    iconPosition="start"
                                                    label={t("bulk_product_index.template_download")}
                                                    value="1"
                                                    color="primary"
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        borderBottom: 1,
                                                        borderColor: "divider",
                                                        mr: 2,
                                                    }}
                                                />
                                                <Tab
                                                    icon={<CloudUploadIcon />}
                                                    iconPosition="start"
                                                    label={t("bulk_product_index.file_upload")}
                                                    value="2"
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        borderBottom: 1,
                                                        borderColor: "divider",
                                                        mr: 2,
                                                    }}
                                                />
                                                <Tab
                                                    icon={<TaskIcon />}
                                                    iconPosition="start"
                                                    label={t("bulk_product_index.reports")}
                                                    value="3"
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        borderBottom: 1,
                                                        borderColor: "divider",
                                                        mr: 2,
                                                    }}
                                                />
                                            </TabList>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                            {/*Tab menu ends here*/}

                            {/*Tab Content starts here*/}
                            <TabPanel value="1" sx={{ p: 0 }}>
                                <TemplateDownload
                                    handleLoader={handleLoader}
                                    showSnackbar={showSnackbar}
                                />
                            </TabPanel>
                            {/* Template download Tab ends here*/}

                            {/* Template upload Tab start here*/}
                            <TabPanel value="2" sx={{ p: 0 }}>
                                <FileUpload
                                    handleLoader={handleLoader}
                                    showSnackbar={showSnackbar}
                                />
                            </TabPanel>
                            {/* Template upload Tab ends here*/}

                            {/* Reports Tab start here*/}
                            <TabPanel value="3" sx={{ p: 0 }}>
                                <Reports
                                    handleLoader={handleLoader}
                                    showSnackbar={showSnackbar}
                                />
                                {/* Table data ends here */}
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Container>
            </div>
        </>
    );
};

export default BulkProductUpload;
