import React, { useState, useMemo, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Brush, ResponsiveContainer, } from "recharts";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useTranslation } from "react-i18next";
import { Box, Button, ButtonGroup, Container, Grid, IconButton, Switch, TextField, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { CloseOutlined } from "@mui/icons-material"; 
import Utility from "../../utils/Utility";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import ReportService from "./services/reportService";
import downloadDataToExcel from "../../utils/ExcelDownloadUtility";
import { SHEET_NAME } from "../../constants/constants";
import DownloadButton from "../common/DownloadBtn";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Skeleton from "../common/Skeleton";


dayjs.extend(isBetween);

const Report = (props: any) =>{
    const { showSnackbar } = props;
    const [data, setData] = useState<any>([]);
    const [startDate, setStartDate] = useState(new Date("2020-01-01"));
    const [endDate, setEndDate] = useState(new Date());
    const [filterRange, setFilterRange] = useState("");
    const [startIndex, setStartIndex] = useState(0);
    const [highlightedPoints, setHighlightedPoints] = useState<number[]>([]);
    const [endIndex, setEndIndex] = useState(data.length - 1);
    const [hoveredLine, setHoveredLine] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const utility = new Utility();
    const [isGraphVisible, setIsGraphVisible] = useState(true);
    const [skeleton, setSkeleton] = useState(true);
    const [activeSeries, setActiveSeries] = useState({
        number_of_orders:false,
        average_product_sales_amount:false,
        average_number_of_items_sold:false,
        product_refund_amount:false,
        order_return_rate:false,
        number_of_product_return:false,
        number_of_items_sold: true,
        product_sales_amount: true,
    });
    const tableKeys = [
        { key: "number_of_orders", value: "Number of orders", unit: "" },
        { key: "average_product_sales_amount", value: "Average product sales amount(¥)", unit: "¥" },
        { key: "average_number_of_items_sold", value: "Average number of items sold", unit: "" },
        { key: "product_refund_amount", value: "Product refund amount(¥)", unit: "¥" },
        { key: "order_return_rate", value: "Order return rate(%)", unit: "%" },
        { key: "number_of_product_return", value: "Number of product return", unit: "" },
        { key: "number_of_items_sold", value: "Number of items sold", unit: "" },
        { key: "product_sales_amount", value: "Product sales amount(¥)", unit: "¥" },
    ];
    const [line1, setLine1] = useState<any>({ key: "number_of_items_sold", value: "Number of items sold", unit: "" });
    const [line2, setLine2] = useState<any>({ key: "product_sales_amount", value: "Product sales amount(¥)", unit: "¥" });
    const { t } = useTranslation();
    const apiData: any[][] = [];

    const orderLevelReportApiCall = () => {
        const storeCode = "m00001";
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);
        new ReportService(payload, isHitDummyURL)
            .orderLevelReport(storeCode)
            .then((res: any) => {
                setData(res);
                setStartDate(new Date(res[0].date));
                setSkeleton(false);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };

    useEffect(() => {
        orderLevelReportApiCall();
    }, []);

    const downloadReporttData = () => {
        showSnackbar(t("activity_log.download_started"), true);
        setLoading(true);
        const storeCode = "m00009";
        const payload = {};
        const isHitDummyURL = false;
        new ReportService(payload, isHitDummyURL)
            .downloadOrderRepoart(storeCode)
            .then((res: any) => {
                handleOnExport(res);
            })
            .catch((err: any) => {
                console.log("err", err);
                showSnackbar(err?.message, false);
                setLoading(false);
            });
    };
    const handleOnExport = (downloadArray?: any) => {
        const downloadData: any = [];
        downloadArray?.map((item: any) => {
            downloadData.push({
                "Date": item?.date,
                "Number of orders": item?.number_of_orders,
                "Product sales amount(¥)": item?.product_sales_amount,
                "Average product sales amount(¥)": item?.average_product_sales_amount,
                "Number of items sold": item?.number_of_items_sold,
                "Average number of items sold": item?.average_number_of_items_sold,
                "Product refund amount(¥)": item?.product_refund_amount,
                "Number of product return": item?.number_of_product_return,
                "Order return rate(%)": item?.order_return_rate
            });
        });
        const fileName = "ストア売上一覧.xlsx";
        downloadDataToExcel(downloadData, fileName, SHEET_NAME.SALES_LIST);
        setLoading(false);
    };


    const columns = [
        {
            name: t("admin_users_list.s_no"),
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "Date",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "Number of orders",
            label: "number_of_orders",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={() => handleLegendClick(filterdata)} >
                                {(data.label == line1.key || data.label == line2.key) ? <ShowChartIcon className="chartIconPosition" /> : <ShowChartIcon className="swapVertIcon" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: "Product sales amount(¥)",
            label: "product_sales_amount",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={() => handleLegendClick(filterdata)} >
                                {(data.label == line1.key || data.label == line2.key) ? <ShowChartIcon className="chartIconPosition" /> : <ShowChartIcon className="swapVertIcon" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: "Average product sales amount(¥)",
            label: "average_product_sales_amount",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={() => handleLegendClick(filterdata)} >
                                {(data.label == line1.key || data.label == line2.key) ? <ShowChartIcon className="chartIconPosition" /> : <ShowChartIcon className="swapVertIcon" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: "Number of items sold",
            label: "number_of_items_sold",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={() => handleLegendClick(filterdata)} >
                                {(data.label == line1.key || data.label == line2.key) ? <ShowChartIcon className="chartIconPosition" /> : <ShowChartIcon className="swapVertIcon" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: "Average number of items sold",
            label: "average_number_of_items_sold",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={() => handleLegendClick(filterdata)} >
                                {(data.label == line1.key || data.label == line2.key) ? <ShowChartIcon className="chartIconPosition" /> : <ShowChartIcon className="swapVertIcon" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: "Product refund amount(¥)",
            label: "product_refund_amount",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={() => handleLegendClick(filterdata)} >
                                {(data.label == line1.key || data.label == line2.key) ? <ShowChartIcon className="chartIconPosition" /> : <ShowChartIcon className="swapVertIcon" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: "Number of product return",
            label: "number_of_product_return",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={() => handleLegendClick(filterdata)} >
                                {(data.label == line1.key || data.label == line2.key) ? <ShowChartIcon className="chartIconPosition" /> : <ShowChartIcon className="swapVertIcon" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
        {
            name: "Order Return Rate(%)",
            label: "order_return_rate",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: (data: any) => {
                    const filterdata = {
                        dataKey: data.label
                    };
                    return (
                        <Box>
                            {data.name}
                            <IconButton color="primary" size="small" onClick={() => handleLegendClick(filterdata)} >
                                {(data.label == line1.key || data.label == line2.key) ? <ShowChartIcon className="chartIconPosition" /> : <ShowChartIcon className="swapVertIcon" />}
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
    ];
    const handleHighlightPoints = () => {
        const filteredIndices: number[] = [];
        data.forEach((item: any, index: any) => {
            const date = new Date(item.date);
            if (index % 6 == 0) {
                filteredIndices.push(index);
            }
        });
        setHighlightedPoints(filteredIndices);
    };
    const handleHighlightforMonth = () => {
        const filteredIndices: number[] = [];
        data.forEach((item: any, index: any) => {
            const date = new Date(item.date);
            if (index % 29 == 0) {
                filteredIndices.push(index);
            }
        });
        setHighlightedPoints(filteredIndices);
    };
    const handleHighlightforDays = () => {
        const filteredIndices: number[] = [];
        data.forEach((item: any, index: any) => {
            filteredIndices.push(index);
        });
        setHighlightedPoints(filteredIndices);
    };
    const renderCustomDotUV = (props: any) => {
        const { cx, cy, stroke, index } = props;
        if (highlightedPoints.includes(index)) {
            return (
                <circle
                    key={`item-${index}`}
                    cx={cx}
                    cy={cy}
                    r={3}
                    fill="#82ca9d"
                    stroke={stroke}
                    strokeWidth={5}
                />
            );
        }
        return <></>;
    };
    const renderCustomDotPV = (props: any) => {
        const { cx, cy, stroke, index } = props;
        if (highlightedPoints.includes(index)) {
            return (
                <circle
                    key={`item-${index}`}
                    cx={cx}
                    cy={cy}
                    r={3}
                    fill="#8884d8"
                    stroke={stroke}
                    strokeWidth={5}
                />
            );
        }
        return <></>;
    };

    useEffect(() => {
        const allIndices = data.map((_: any, index: any) => index);
        setHighlightedPoints(allIndices);
    }, [data]);

    useEffect(() => {
        const startIdx = data.findIndex((item: any) => new Date(item.date) >= new Date(startDate));
        const endIdx = data.findIndex((item: any) => new Date(item.date) >= new Date(endDate));
        setStartIndex(startIdx >= 0 ? startIdx : 0);
        setEndIndex(endIdx >= 0 ? endIdx : data.length - 1);
    }, [startDate, endDate]);

    const handleStartDateChange = (date: any) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: any) => {
        setEndDate(date);
    };

    const handleFilterRangeChange = (range: any) => {
        setFilterRange(range);
        applyFilter(range);
    };

    const handleSwap = () => {
        const swapLineGraph1 = line1;
        setLine1(line2);
        setLine2(swapLineGraph1);
    };

    const applyFilter = (range: any) => {
        const now = dayjs();
        switch (range) {
        case "7d":
            setStartDate(now.subtract(7, "day").toDate());
            setEndDate(now.toDate());
            break;
        case "1m":
            setStartDate(now.subtract(1, "month").toDate());
            setEndDate(now.toDate());
            break;
        case "3m":
            setStartDate(now.subtract(3, "month").toDate());
            setEndDate(now.toDate());
            break;
        case "6m":
            setStartDate(now.subtract(6, "month").toDate());
            setEndDate(now.toDate());
            break;
        case "1y":
            setStartDate(now.subtract(1, "year").toDate());
            setEndDate(now.toDate());
            break;
        case "2y":
            setStartDate(now.subtract(2, "year").toDate());
            setEndDate(now.toDate());
            break;
        default:
            setStartDate(now.subtract(3, "year").toDate());
            // setStartDate(new Date("2023-01-01"));
            setEndDate(now.toDate());
            break;
        }
    };

    const filterDataByDateRange = (start: any, end: any) => {
        if (!start || !end) return data;

        const startDate = new Date(start);
        const endDate = new Date(end);

        const filtered = data.filter((d: any) => {
            const currentDate = new Date(d.date);
            return (currentDate >= startDate && currentDate <= endDate);
        });

        return filtered;
    };

    const filteredData = useMemo(
        () => filterDataByDateRange(startDate, endDate),
        [startDate, endDate]
    );

    const handleBrushChange = (e: any) => {
        const { startIndex, endIndex } = e || {};
        if (startIndex !== undefined && endIndex !== undefined) {
            const brushStartDate = data[startIndex]?.date;
            const brushEndDate = data[endIndex]?.date;
            setStartDate(new Date(brushStartDate));
            setEndDate(new Date(brushEndDate));
            filterDataByDateRange(new Date(brushStartDate), endDate);
        }
    };

    const handleLegendClick = (o: any) => {
        const { dataKey, type } = o;
        setActiveSeries((prevState: any) => {
            const activeKeys = Object.keys(prevState).filter(
                (key) => prevState[key] === true
            );

            if (activeKeys.length === 2 && !prevState[dataKey]) {
                showSnackbar("Please deselect at least one series before selecting a new one.", false);
                return prevState;
            }

            const selectedSeries = tableKeys.find((item) => item.key === dataKey);

            const newActiveSeries = {
                ...prevState,
                [dataKey]: !prevState[dataKey],
            };

            if (newActiveSeries[dataKey]) {
                if (!line1.key) {
                    setLine1(selectedSeries);
                } else if (!line2.key) {
                    setLine2(selectedSeries);
                }
            } else if (line1.key === dataKey) {
                setLine1({ key: "", value: "", unit: "" });
                setLine1(line2);
                setLine2({ key: "", value: "", unit: "" });
            } else if (line2.key === dataKey) {
                setLine2({ key: "", value: "", unit: "" });
            }
            return newActiveSeries;
        });
    };

    const dayDifference = dayjs(filteredData[filteredData.length - 1]?.date).diff(dayjs(filteredData[0]?.date), "day");

    const tickFormatter = (date: any) => {
        if (dayDifference > 365) {
            return dayjs(date).format("YYYY"); // Format as full year
        } else if (dayDifference > 30) {
            return dayjs(date).format("MMM YYYY"); // Format as month and year
        } else {
            return dayjs(date).format("DD MMM"); // Format as day and month
        }
    };

    const numberWithCommas = (value: any) => {
        return (
            <Box sx={{ textAlign: "right" }}>{utility.numberWithCommas(value)}</Box>
        );
    };
    const apiTableData = () => {
        {
            data?.map((item: any, index: number) => {
                apiData[index] = [];
                apiData[index].push(
                    numberWithCommas(index + 1),
                    <Box sx={{ textAlign: "right" }}>{item?.date}</Box>,
                    numberWithCommas(item?.number_of_orders),
                    numberWithCommas(item?.product_sales_amount),
                    numberWithCommas(item?.average_product_sales_amount),
                    numberWithCommas(item?.number_of_items_sold),
                    numberWithCommas(item?.average_number_of_items_sold),
                    numberWithCommas(item?.product_refund_amount),
                    numberWithCommas(item?.number_of_product_return),
                    numberWithCommas(item?.order_return_rate)
                );
            });
        }
    };
    apiTableData();

    const handleMouseEnter = (lineKey: any) => {
        setHoveredLine(lineKey);
    };
    const handleToggle = (dataKey: string) => {
        if (hoveredLine === dataKey) {
            handleMouseLeave();
            setHoveredLine("");
        } else {
            handleMouseEnter(dataKey);
            setHoveredLine(dataKey);
        }
    };

    const handleMouseLeave = () => {
        setHoveredLine("");
    };

    const handleRemoveLine = (dataKey: any) => {
        setActiveSeries((prevState: any) => {

            const selectedSeries = tableKeys.find((item) => item.key === dataKey);

            const newActiveSeries = {
                ...prevState,
                [dataKey]: !prevState[dataKey],
            };

            if (newActiveSeries[dataKey]) {
                if (!line1.key) {
                    setLine1(selectedSeries);
                } else if (!line2.key) {
                    setLine2(selectedSeries);
                }
            } else {
                if (line1.key === dataKey) {
                    setLine1({ key: "", value: "", unit: "" });
                    setLine1(line2);
                    setLine2({ key: "", value: "", unit: "" });
                } else if (line2.key === dataKey) {
                    setLine2({ key: "", value: "", unit: "" });
                }
            }
            return newActiveSeries;
        });
    };
    const CustomLegend = (props: any) => {
        const { payload } = props;
        return (
            <Box display="flex" alignItems="center" flexWrap="wrap" padding="10px">
                {payload.map((entry: any, index: any) => (
                    <Button key={`item-${index}`} variant="outlined" size="small"
                        sx={{ margin: "5px", borderColor: entry.color, color: entry.color, display: "flex", alignItems: "center", }}
                    >
                        <Box display="flex" alignItems="center" style={{ marginRight: "5px" }}>
                            <Box width="12px" height="12px" borderRadius="50%" bgcolor={entry.color} marginRight="8px"></Box>
                            <Typography variant="body2" onClick={() => handleToggle(entry.dataKey)}>{entry.value}</Typography>
                        </Box>
                        <CloseOutlined style={{ fontSize: "14px", color: entry.color }} onClick={() => handleRemoveLine(entry.dataKey)} />
                    </Button>
                ))}
                {(line1.value && line2.value) ? <IconButton onClick={handleSwap} sx={{ marginLeft: "10px", color: "#000" }} >
                    <SwapHorizIcon />
                </IconButton> : null}
            </Box>
        );
    };
    const handleSwitchChange = (event: any) => {
        setIsGraphVisible(event.target.checked);
    };
    return (
        <>

            <Container sx={{maxWidth: "1170px"}}>
                <Grid container mt={4}>
                    <Grid item xs={6} sm={6} md={6} lg={9} xl={6}>
                        <Typography variant="h5">{"Order Level Business Report"}</Typography>
                        <Typography
                            sx={{ borderBottom: "3px solid red", width: "50px" }}
                        ></Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={6}>
                        <Switch  className = "float_right" checked={isGraphVisible} onChange={handleSwitchChange} />
                    </Grid>
                </Grid>

                <Grid container mt={4}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={4} >
                        <Typography  >{"View By"}</Typography>
                        <ButtonGroup variant="outlined" aria-label="Basic button group" >
                            <Button onClick={handleHighlightforDays}>{"Days"}</Button>
                            <Button onClick={handleHighlightPoints}>{"Week"}</Button>
                            <Button onClick={handleHighlightforMonth}>{"Month"}</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={4}>
                        <Typography style={{  marginRight: 5 }} >{"Zoom"}</Typography>
                        <ButtonGroup variant="outlined" aria-label="Basic button group" >
                            <Button variant={filterRange == "7d" ? "contained" : "outlined"} onClick={() => handleFilterRangeChange("7d")}>{"7D"}</Button>
                            <Button variant={filterRange == "1m" ? "contained" : "outlined"} onClick={() => handleFilterRangeChange("1m")}>{"1M"}</Button>
                            <Button variant={filterRange == "3m" ? "contained" : "outlined"} onClick={() => handleFilterRangeChange("3m")}>{"3M"}</Button>
                            <Button variant={filterRange == "6m" ? "contained" : "outlined"} onClick={() => handleFilterRangeChange("6m")}>{"6M"}</Button>
                            <Button variant={filterRange == "1y" ? "contained" : "outlined"} onClick={() => handleFilterRangeChange("1y")}>{"1Y"}</Button>
                            <Button variant={filterRange == "2y" ? "contained" : "outlined"} onClick={() => handleFilterRangeChange("2y")}>{"2Y"}</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={4} >
                        <Grid container spacing={2} sx={{ marginTop: "6px" }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <DatePicker
                                        label="From"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    ".MuiInputBase-root": {
                                                        height: "36px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={5} sm={5} md={5} lg={6} xl={6}>
                                    <DatePicker
                                        label="To"
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    ".MuiInputBase-root": {
                                                        height: "36px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>


            <Container>
                {skeleton ? (
                    <Skeleton
                        varient="rectangular"
                        width={0}
                        height={60}
                        count="10"
                        my={0}
                    />
                ) : (
                    isGraphVisible && (
                        <Box>
                            <ResponsiveContainer width="98%" height={500}>
                                <LineChart
                                    data={filteredData}
                                    syncId="mainChart"
                                    margin={{ top: 10, right: 30, left: 40, bottom: 5 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date"
                                        tickFormatter={tickFormatter}
                                        tick={{ fontSize: 12, fill: "#666" }}
                                        interval={
                                            dayDifference > 365 ? 365 :   
                                                dayDifference > 30 ? 30 : 0   
                                        }
                                        minTickGap={50}
                                    />
                                    <YAxis
                                        yAxisId="left"
                                        tickMargin={2}
                                        label={{
                                            value: `${line1.value}`,
                                            offset: -20,
                                            angle: -90,
                                            position: "insideLeft",
                                            style: { fill: "#8884d8" }
                                        }}
                                        tick={{ fill: "#8884d8" }}
                                        axisLine={{ stroke: "#8884d8" }}
                                        tickFormatter={(value: any) => line1.unit == "¥" ? `${line1.unit} ${utility.numberWithCommas(value)}` : `${utility.numberWithCommas(value)} ${line1.unit}`}
                                    />
                                    <YAxis
                                        yAxisId="right"
                                        orientation="right"
                                        tickMargin={2}
                                        label={{
                                            value: `${line2.value}`,
                                            offset: 20,
                                            angle: -90,
                                            position: "right",
                                            style: { fill: "#82ca9d" }
                                        }}
                                        tick={{ fill: "#82ca9d" }}
                                        axisLine={{ stroke: "#82ca9d" }}
                                        tickFormatter={(value: any) => line2.unit == "¥" ? `${line2.unit} ${utility.numberWithCommas(value)}` : `${utility.numberWithCommas(value)} ${line2.unit}`}
                                    />
                                    <Tooltip
                                        formatter={(value: any, name: any, props: any) => {
                                            if (name === line1?.value) {
                                                return [line1.unit === "¥" ? ` ${line1.unit}${utility.numberWithCommas(String(value))}` : ` ${utility.numberWithCommas(String(value))}${line1.unit}`, line1?.value];
                                            }
                                            if (name === line2?.value) {
                                                return [line2.unit === "¥" ? ` ${line2.unit}${utility.numberWithCommas(String(value))}` : ` ${utility.numberWithCommas(String(value))}${line2.unit}`, line2?.value];
                                            }
                                            return value;
                                        }}
                                    />
                                    <Legend
                                        content={CustomLegend}
                                        layout="horizontal"
                                        verticalAlign="top"
                                        align="left"
                                        // wrapperStyle={{ top:10, left:20, right:12 }}
                                        height={80}
                                        // width={13}
                                        onClick={() => handleMouseEnter(line1?.key)}
                                        iconSize={19}
                                        chartHeight={11}
                                        chartWidth={12}
                                        iconType="circle"
                                    />
                                    {line1?.key && <Line
                                        yAxisId="left"
                                        key={line1?.key}
                                        type="monotone"
                                        dataKey={line1?.key}
                                        stroke={hoveredLine === line1?.key ? "#8884d8" : (hoveredLine ? "#d3d3d3" : "#8884d8")}
                                        activeDot={{ r: 8 }}
                                        hide={!line1?.key}
                                        strokeWidth={3}
                                        dot={renderCustomDotPV}
                                        width={9000}
                                        height={500}
                                        name={line1?.value}
                                        onMouseEnter={() => handleMouseEnter(line1?.key)}
                                        onMouseLeave={handleMouseLeave}
                                        cursor="pointer"
                                    />}
                                    {line2?.key && <Line
                                        yAxisId="right"
                                        key={line2?.key}
                                        type="monotone"
                                        dataKey={line2?.key}
                                        stroke={hoveredLine === line2?.key ? "#82ca9d" : (hoveredLine ? "#d3d3d3" : "#82ca9d")} // Highlight or grey out based on hover
                                        hide={!line2?.key}
                                        strokeWidth={3}
                                        dot={renderCustomDotUV}
                                        name={line2?.value}
                                        onMouseEnter={() => handleMouseEnter(line2?.key)}
                                        onMouseLeave={handleMouseLeave}
                                        cursor="pointer"
                                    />}
                                    {!line1?.key && !line2?.key && (
                                        <text
                                            x="50%"
                                            y="50%"
                                            textAnchor="middle"
                                            dominantBaseline="middle"
                                            style={{ fontSize: "16px", fill: "#888" }}
                                        >
                                            {" No line selected"}
                                        </text>
                                    )}
                                </LineChart>
                            </ResponsiveContainer>

                            <ResponsiveContainer width="96%" height={90}>
                                <LineChart
                                    data={data}
                                    // syncId="mainChart"
                                    margin={{ top: 0, right: 0, left: 50, bottom: 0 }}
                                >
                                    <YAxis yAxisId="left" orientation="left" hide={true} />
                                    <YAxis yAxisId="right" orientation="right" hide={true} />
                                    <XAxis dataKey="date"
                                        // tickFormatter={tickFormatter}
                                        tick={{ fontSize: 12, fill: "#666" }}
                                    />

                                    <Line
                                        type="monotone"
                                        dataKey={line1.key}
                                        stroke="#8884d8"
                                        dot={false}
                                        className="line-chart"
                                        yAxisId="left"
                                    />
                                    <Line
                                        className="line-chart"
                                        type="monotone"
                                        dataKey={line2.key}
                                        stroke="#82ca9d"
                                        dot={false}
                                        yAxisId="right"
                                    />
                                </LineChart>
                            </ResponsiveContainer>

                            <div style={{ marginTop: 20 }} className="rechart">
                                <ResponsiveContainer width="96%" height={60}>
                                    <AreaChart
                                        data={data}
                                        syncId="mainChart"
                                        margin={{ top: 0, right: 40, left: 20, bottom: 0 }}
                                    >
                                        <YAxis yAxisId="left" orientation="left" hide={true} />
                                        <YAxis yAxisId="right" orientation="right" hide={true} />
                                        <XAxis dataKey="date" hide />

                                        <Brush
                                            dataKey="date"
                                            tickFormatter={tickFormatter}
                                            // tick={{ fontSize: 12, fill: "#666" }}
                                            data={data}
                                            startIndex={startIndex}
                                            endIndex={endIndex}
                                            height={30}
                                            stroke="#00684D"
                                            onDragEnd={handleBrushChange}
                                        >
                                            <XAxis
                                                dataKey="date"
                                                tick={{ fontSize: 10 }}
                                                tickLine={false}
                                                tickFormatter={(value: any) => String(new Date(value).getFullYear())}
                                            />
                                        </Brush>
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </Box>
                    )
                )}

                <Container className="chart_table" >
                    <Grid container>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}></Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{ float: "right", mt: 5 }} >
                            <DownloadButton loading={loading} route={window.location.pathname} className="float_right" functionToExecute={downloadReporttData} btnName={t("pr.download")} disableCondition={loading} />
                        </Grid>
                    </Grid>
                    <MUIDataTable
                        title={""}
                        columns={columns}
                        data={apiData}
                        options={{
                            pagination: true,
                            print: false,
                            download: false,
                            filter: false,
                            viewColumns: false,
                            search: false,
                            selectableRowsHideCheckboxes: true,
                            textLabels: {
                                body: {
                                    noMatch: t("table_text.no_match").toString(),
                                },
                                viewColumns: {
                                    title: t("table_text.show_columns").toString(),
                                    titleAria: "Show/Hide Table Columns",
                                },
                                toolbar: {
                                    search: t("table_text.search").toString(),
                                    viewColumns: t("table_text.view_columns").toString(),
                                },
                            },
                            searchPlaceholder: t("table_text.search_placeholder").toString(),
                            responsive: "standard",
                        }} />
                </Container>
            </Container>
        </>
    );
    
};
export default Report;