export const mockAPIPath = {
    MANAGE_INVENTORY_LIST: "./data/sellerReoprts.json",
    INVENTORY_REPORTS: "./data/sellerReoprts.json",
    INVENTORY_INFO_REPORTS: "./data/sellerInfo.json",
    INVENTORY_DOWNLOAD: "./data/inventoryTemplateDownload.json",
    UPDATE_INVENTORY: "/data/updateInventory.json",
    INVENTORY_FILE_UPLOAD: "/data/inventoryFileUpload.json",
    DOWNLOAD_TEMPLATE: "/products/inventory/template"
};

export const serverAPIPath = {
    MANAGE_INVENTORY_LIST: "./data/manageInventoryList.json",
    INVENTORY_REPORTS: "/products/inventory/upload/report",
    INVENTORY_INFO_REPORTS: "/products/inventory/upload/report",
    INVENTORY_DOWNLOAD: "/products/inventory/upload/errors/download/",
    DOWNLOAD_TEMPLATE: "/products/inventory/template",
    INVENTORY_FILE_UPLOAD: "/products/inventory/upload/file",
    LATEST_INVENTORY_INFO_REPORTS: "/products/inventory/upload/report/latest",
    MANAGE_INVENTORY_QUANTITY: ""
};

export const devServerAPIPath = {
    MANAGE_INVENTORY_LIST: "/mule/inventories",
    MANAGE_INVENTORY_QUANTITY: "/mule/inventories"
};





