export const mockAPIPath = {
    ORDER_LEVEL_REPORT:"./data/orderLevelReport.json",
    PRODUCT_LEVEL_REPORT:"",
    PRODUCT_LEVEL_REPORT_TABLE:"",
    PRODUCT_LIST: "",
};

export const serverAPIPath = {
    ORDER_LEVEL_REPORT:"/report/order",
    PRODUCT_LEVEL_REPORT:"/report/product",
    PRODUCT_LEVEL_REPORT_TABLE:"/report/product/table",
    PRODUCT_LIST: "/products",
};