import React, { useEffect, useState } from "react";
import Header from "./components/common/Header/";
import Footer from "./components/common/Footer";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import ForgotPassword from "./components/Auth/ForgotPassword/SendOTP";
import VerifiedOTP from "./components/Auth/ForgotPassword/VerifyOTP";
import CreatePassword from "./components/Auth/ForgotPassword/CreatePassword";
import VerifyPassword from "./components/Auth/ResetPassword/VerifyPassword";
import SendOTP from "./components/Auth/ForgotPassword/SendOTP";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import BulkProductUpload from "./components/BulkProductUpload";
import UpdatePassword from "./components/Auth/ResetPassword/UpdatePassword";
import Cookies from "./utils/Cookies";
import Page404 from "./components/common/Page404";
import ProductList from "./components/BulkProductUpload/ProductList";
import Profile from "./components/Profile";
import PrivacyPolicy from "./components/Profile/PrivacyPolicy";
import ShippingPolicy from "./components/Profile/ShippingPolicy";
import CustomPolicy from "./components/Profile/CustomPolicy";
import AboutUs from "./components/Profile/AboutUs";
import FAQs from "./components/Profile/FAQs";
import VerifyInvitation from "./components/common/VerifyInvitation";
import InventoryManage from "./components/InventoryManage";
import Manageorder from "./components/ManageOrder/index";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { loaderActions } from "./redux/features/loader/loaderSlice";
import Decline from "./components/ManageOrder/Decline/index";
import ImageUpload from "./components/ImageUpload";
import ExhibitionUsers from "./components/ExhibitionUser";
import InviteUserSeller from "./components/common/invitationPage";
import ExhibitionUsersDetails from "./components/ExhibitionUser/ExhibitionUserDetails";
import StoreDetails from "./components/Profile/StoreDetails";
import UnauthorizedAccess from "./components/common/UnauthorizedAccess";
import { commonActions } from "./redux/features/common/commonSlice";
import AuthService from "./components/Auth/services/AuthService";
import { permissionActions } from "./redux/features/roleBasedPermission/permissionSlice";
import { newPermissionCategory, permissionCategory } from "./constants/permissionCategory";
import BillingInformation from "./components/Profile/BillingInformation";
import PaymentInformation from "./components/Profile/PaymentInformation";
import PayeeInformation from "./components/Profile/PayeeInformation";
import CONSTANTS from "./constants/constants";
import { permissionInitialValue } from "./interfaces/permissionInterface";
import { commonInitialValue } from "./interfaces/commonInterface";
import { ROUTES } from "./constants/routes";
import UpdateEmail from "./components/ExhibitionUser/EmailUpdate/UpdateEmail";
import EmailOTP from "./components/ExhibitionUser/EmailUpdate/OtpEmail";
import Oms from "./components/Oms";
import AdminAuthentication from "./components/Auth/AdminAuthentication";
import ActivityLog from "./components/ActivityLog";
import ShippingMultipleOrder from "./components/Oms/ShipingMultipleOrder";
import OMSConfirmShipment from "./components/Oms/OMSConfirmShipment";
import PrintPackingSlip from "./components/common/PrintPackingSlip";
import BulkImageUpload from "./components/BulkImageUpload";
import RefundOrder from "./components/Oms/Refund";
import PaymentReports from "./components/PaymentReports";
import InternetConnection from "./checkConnection/index";
import UnderMaintenance from "./components/UnderMaintenance";
import SalesHistory from "./components/SalesHistory";
import { ProtectedRoute } from "./middleware/ProtectedRoute";
import { ProtectedRouteForAdmin } from "./middleware/ProtectedRouteForAdmin";
import { newPermissionActions } from "./redux/features/newRoleBasedPermission/newPermissionSlice";
import { snackbarUtils } from "./utils/snackbarUtils";
import SingleProductDetails from "./components/BulkProductUpload/SingleProductDetails";
import ProductFileDownload from "./components/BulkProductUpload/ProductList/productDownload";
import ErrorDownloadProduct from "./components/common/ErrorDownloadProduct";
import Utility from "./utils/Utility";
import ModalUtility from "./utils/modalUtility";
import { useTranslation } from "react-i18next";
import ProductReport from "./components/Report/productLevelReport";
import Report from "./components/Report";

function App() {
    const dispatch = useAppDispatch();
    const [cookie, setCookie] = useState(new Cookies("access-token").read());
    const [isStoreCreated, setIsStoreCreated] = useState(useAppSelector(state => state.common.isStoreCreated));
    const [loggedInUserType, setLoggedInUserType] = useState(useAppSelector(state => state.permission.permissionDetails.userType));
    const [newSellerLogin, setNewSellerLogin] = useState<boolean>(false);
    const [isMsgRequired, setIsMsgRequired] = useState<any>(localStorage.getItem("systemUpdateNotification"));
    const [version, setVersion] = useState<any>(localStorage.getItem("backendVersion"));
    const [isUnderMaintenance,setIsUnderMaintenance] = useState<any>(sessionStorage.getItem("isUnderMaintenance"));
    const [showModal,setShowModal] = useState<any>(false);
    const [modalOpen, setModalOpen] = useState<any>(false);
    const utility = new Utility;
    const [t, i18n] = useTranslation();

    useEffect(() => {
        utility.connectionToSSE(setIsMsgRequired,setVersion,setIsUnderMaintenance);
    }, []);
    
    useEffect(() => {
        const storedBackendVersion = localStorage.getItem("backendVersion");
        if (storedBackendVersion != version) {
            window.history.pushState(null, "", window.location.pathname);
            setModalOpen(true); 
            localStorage.setItem("backendVersion", version);
        }
    }, [version]); 

    useEffect(() => {
        const storedIsUnderMaintenance = sessionStorage.getItem("isUnderMaintenance");
        if (storedIsUnderMaintenance != isUnderMaintenance) { 
            setShowModal(isUnderMaintenance);
            if(isUnderMaintenance === "false"){
                window.location.reload();
            } 
            sessionStorage.setItem("isUnderMaintenance", isUnderMaintenance);
        }
    }, [isUnderMaintenance]);

    const loginFunc = async (token: string, isStoreCreated: boolean, userType: number, email: string, name: string, storeId: number, storeName: string, storeCode: number, perPageLimit: number, roleId: any) => {
        new Cookies("access-token", token).save();
        // new Cookies("isStoreCreated", isStoreCreated).save();
        dispatch(commonActions.IS_STORE_CREATED(isStoreCreated));
        dispatch(commonActions.SAVE_LOGIN_USER_EMAIL(email));
        dispatch(commonActions.SAVE_STORE_ID(storeId));
        dispatch(commonActions.SAVE_STORE_NAME(storeName));
        dispatch(commonActions.SAVE_STORE_CODE(storeCode));
        dispatch(commonActions.SAVE_PER_PAGE_LIMIT(perPageLimit));
        name && userType != CONSTANTS.USER_TYPE_ADMIN ? dispatch(commonActions.SAVE_LOGIN_USER_NAME(name)) : null;
        userType == CONSTANTS.USER_TYPE_ADMIN ? dispatch(commonActions.SAVE_LOGIN_ADMIN_USER_NAME(name)) : null;
        setCookie(token);
        setIsStoreCreated(isStoreCreated);
        setLoggedInUserType(userType ? userType : userType == CONSTANTS.USER_TYPE_SUPER_ADMIN ? CONSTANTS.USER_TYPE_SUPER_ADMIN : loggedInUserType);
        await newGetPermissions(roleId);
        await getPermissions();
    };

    const getPermissions = async () => {
        new AuthService({}, false).getRolePermissions().then((res) => {
            dispatch(permissionActions.SET_PERMISSIONS(res));
        }).catch(err =>showSnackbar(err?.message, false));
    };

    const newGetPermissions = async (roleId: any) => {
        if (roleId) {
            new AuthService({}, false)
                .newGetRolePermissions(roleId)
                .then((res) => {
                    dispatch(newPermissionActions.NEW_SET_PERMISSIONS(res));
                }).catch(err =>showSnackbar(err?.message, false));
        } else {
            new AuthService({}, false)
                .newGetRolePermissions(0)
                .then((res) => {
                    dispatch(newPermissionActions.NEW_SET_PERMISSIONS(res));
                }).catch(err =>showSnackbar(err?.message, false));
        }

    };

    const logoutFunc = () => {
        dispatch(permissionActions.RESET_PERMISSIONS(permissionInitialValue.permissionDetails));
        dispatch(commonActions.RESET_IS_STORE_CREATED(commonInitialValue.isStoreCreated));
        dispatch(commonActions.SAVE_OPEN_PROGRESSBAR(false));
        dispatch(commonActions.SAVE_OPEN_PROGRESSBAR_BULK_IMAGE_UPLOAD(false));
        dispatch(commonActions.PROGRESSBAR_MESSAGE_INVENTORY((false)));
        dispatch(commonActions.PROGRESSBAR_MESSAGE(0));
        dispatch(commonActions.PROGRESSBAR_MESSAGE_BULK_IMAGE_UPLOAD(0));
        dispatch(commonActions.PROGRESSBAR_MESSAGE_INVENTORY(0));
        dispatch(commonActions.PROGRESSBAR_MESSAGE(0));
        dispatch(commonActions.IS_RELOAD(true));

        setCookie("");
        setIsStoreCreated(null);
        setLoggedInUserType(null);
    };

    // const showSnackbar = (message: string, success: boolean, autoClose: boolean) => {
    //     dispatch(snackbarActions.SHOW_SNACKBAR({
    //         show: true,
    //         message,
    //         success,
    //         autoClose
    //     }));
    // };

    const showSnackbar = (message: string, success: boolean, autoClose = true) => {
        snackbarUtils(dispatch,message, success, autoClose);
    };  
    
    const handleLoader = (message: boolean) => {
        dispatch(loaderActions.SHOW_LOADER(message));
    };



    const routesListWithoutLogin = [
        { path: ROUTES.REGISTER, element: <Register login={loginFunc} handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { path: ROUTES.VERIFY_INVITATION, element: <VerifyInvitation /> },
        { path: ROUTES.FORGOT_PASSWORD, element: <ForgotPassword handleLoader={handleLoader} showSnackbar={showSnackbar}  /> },
        { path: ROUTES.SEND_OTP, element: <SendOTP showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { path: ROUTES.VERIFY_OTP, element: <VerifiedOTP showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { path: ROUTES.CREATE_PASSWORD, element: <CreatePassword showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { path: ROUTES.INITIAL_PAGE, element: <Login login={loginFunc} showSnackbar={showSnackbar} handleLoader={handleLoader} setIsMsgRequired={setIsMsgRequired} /> },
        { path: ROUTES.ADMIN_AUTH, element: <AdminAuthentication login={loginFunc} showSnackbar={showSnackbar} /> },
        { path: ROUTES.PAGE_NOT_EXIST, element: <Navigate to={ROUTES.INITIAL_PAGE} replace /> },
        { path: ROUTES.UNDER_MAINTENANCE, element: <UnderMaintenance showSnackbar={showSnackbar} /> },
        { path: ROUTES.PRODUCT_DOWNLOAD, element: <ProductFileDownload showSnackbar={showSnackbar}/> },
        { path: ROUTES.ERROR_DOWNLOAD_PRODUCT, element: <ErrorDownloadProduct/> },
    ];

    const routesListAfterLogin = [
        { category: permissionCategory.COMMON, path: ROUTES.UPDATE_PASSWORD, element: <UpdatePassword showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: permissionCategory.COMMON, path: ROUTES.VERIFY_PASSWORD, element: <VerifyPassword showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: permissionCategory.COMMON, path: ROUTES.EMAIL_UPDATE, element: <UpdateEmail showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: permissionCategory.COMMON, path: ROUTES.EMAIL_OTP, element: <EmailOTP showSnackbar={showSnackbar} handleLoader={handleLoader} logout={logoutFunc} /> },
        { category: permissionCategory.COMMON, path: ROUTES.PAGE_NOT_EXIST, element: <Page404 /> },
        { category: permissionCategory.PRODUCT, path: ROUTES.PRODUCT_LIST, element: <ProductList showSnackbar={showSnackbar} /> },
        { category: permissionCategory.COMMON, path: ROUTES.VERIFY_INVITATION, element: <VerifyInvitation /> },
        { category: permissionCategory.PRODUCT, path: ROUTES.IMAGE_UPLOAD, element: <ImageUpload showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: permissionCategory.COMMON, path: ROUTES.PROFILE, element: loggedInUserType == CONSTANTS.USER_TYPE_EXHIBITOR ? <UnauthorizedAccess /> : <Profile setNewSellerLogin={setNewSellerLogin} showSnackbar={showSnackbar}  /> },
        { category: permissionCategory.STORE, path: ROUTES.PRIVACY_POLICY, element: <PrivacyPolicy showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: permissionCategory.STORE, path: ROUTES.SHIPPING_POLICY, element: <ShippingPolicy showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: permissionCategory.STORE, path: ROUTES.CUSTOM_POLICY, element: <CustomPolicy showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: permissionCategory.STORE, path: ROUTES.ABOUT_US, element: <AboutUs showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: permissionCategory.STORE, path: ROUTES.FAQS, element: <FAQs showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: permissionCategory.LISTING_USER, path: ROUTES.INVITE_PAGE, element: <InviteUserSeller showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: permissionCategory.PRODUCT, path: ROUTES.BULK_PRODUCT_UPLOAD, element: <BulkProductUpload handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.PRODUCT, path: ROUTES.BULK_IMAGE_UPLOAD, element: <BulkImageUpload handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.LISTING_USER, path: ROUTES.EXHIBITION_USER_LIST, element: <ExhibitionUsers handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.LISTING_USER, path: `${ROUTES.EXHIBITION_USER_DETAIL}/:id`, element: <ExhibitionUsersDetails handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.LISTING_USER, path: ROUTES.EXHIBITION_USER_DETAIL, element: <ExhibitionUsersDetails handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.INVENTORY, path: ROUTES.MANAGE_INVENTORY, element: <InventoryManage handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.ORDER, path: ROUTES.MANAGE_ORDER, element: <Manageorder handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.ORDER, path: ROUTES.ORDER_DECILNE, element: <Decline handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.COMMON, path: ROUTES.UNAUTHORIZED_VISIT, element: <UnauthorizedAccess /> },
        { category: permissionCategory.STORE, path: ROUTES.STORE_DETAILS, element: <StoreDetails handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.STORE, path: ROUTES.BILLING_INFO, element: <BillingInformation handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.STORE, path: ROUTES.PAYMENT_INFO, element: <PaymentInformation handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.STORE, path: ROUTES.PAYEE_INFO, element: <PayeeInformation handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.COMMON, path: ROUTES.INITIAL_PAGE, element: <Navigate to={ROUTES.OMS} replace /> },
        { category: permissionCategory.COMMON, path: ROUTES.ADMIN_AUTH, element: <AdminAuthentication login={loginFunc} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.COMMON, path: ROUTES.SHIPPING_MULTIPLE_ORDER, element: <ShippingMultipleOrder handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.COMMON, path: ROUTES.OMS, element: <Oms handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.COMMON, path: `${ROUTES.OMS_CONFIRM_SHIPMENT}/:id`, element: <OMSConfirmShipment handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.COMMON, path: ROUTES.PRINT_PACKING_SLIP, element: <PrintPackingSlip  /> },
        { category: permissionCategory.ACTIVITY_LOG, path: ROUTES.ACTIVITY_LOG, element: <ActivityLog handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.COMMON, path: `${ROUTES.REFUND_ORDER}/:id`, element: <RefundOrder handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: permissionCategory.COMMON, path: ROUTES.PAYMENT_REPORTS, element: <PaymentReports showSnackbar={showSnackbar} /> },
        { category: permissionCategory.COMMON, path: ROUTES.SALES_HISTORY, element: <SalesHistory showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: permissionCategory.COMMON, path: ROUTES.UNDER_MAINTENANCE, element: <UnderMaintenance showSnackbar={showSnackbar} /> },
        { category: permissionCategory.STORE, path: ROUTES.SINGLE_PRODUCT_DETAILS, element: <SingleProductDetails showSnackbar={showSnackbar} handleLoader={handleLoader}/> },
        { category: permissionCategory.COMMON, path: ROUTES.PRODUCT_DOWNLOAD, element: <ProductFileDownload showSnackbar={showSnackbar}/> },
        { category: permissionCategory.COMMON, path: ROUTES.ERROR_DOWNLOAD_PRODUCT, element: <ErrorDownloadProduct /> },
        { category: permissionCategory.COMMON, path: ROUTES.PRODUCT_REPORT, element: <ProductReport showSnackbar={showSnackbar} /> },
        { category: permissionCategory.COMMON, path: ROUTES.ORDER_REPORT, element: <Report showSnackbar={showSnackbar} /> },
    ];

    const routesListAfterLoginWithPermission = [
        { category: newPermissionCategory.COMMON, path: ROUTES.UPDATE_PASSWORD, element: <UpdatePassword showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.VERIFY_PASSWORD, element: <VerifyPassword showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.EMAIL_UPDATE, element: <UpdateEmail showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.EMAIL_OTP, element: <EmailOTP showSnackbar={showSnackbar} handleLoader={handleLoader} logout={logoutFunc} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.PAGE_NOT_EXIST, element: <Page404 /> },
        { category: newPermissionCategory.MERCHANDISE_MANAGEMENT, path: ROUTES.PRODUCT_LIST, element: <ProductList showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.VERIFY_INVITATION, element: <VerifyInvitation /> },
        { category: newPermissionCategory.MERCHANDISE_MANAGEMENT, path: ROUTES.IMAGE_UPLOAD, element: <ImageUpload showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.PROFILE, element: loggedInUserType == CONSTANTS.USER_TYPE_EXHIBITOR ? <UnauthorizedAccess /> : <Profile setNewSellerLogin={setNewSellerLogin} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.PRIVACY_POLICY, element: <PrivacyPolicy showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.SHIPPING_POLICY, element: <ShippingPolicy showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.CUSTOM_POLICY, element: <CustomPolicy showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.ABOUT_US, element: <AboutUs showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.FAQS, element: <FAQs showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.INVITE_PAGE, element: <InviteUserSeller showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: newPermissionCategory.MERCHANDISE_MANAGEMENT, path: ROUTES.BULK_PRODUCT_UPLOAD, element: <BulkProductUpload handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.MERCHANDISE_MANAGEMENT, path: ROUTES.BULK_IMAGE_UPLOAD, element: <BulkImageUpload handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.USER_MANAGEMENT, path: ROUTES.EXHIBITION_USER_LIST, element: <ExhibitionUsers handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.USER_MANAGEMENT, path: `${ROUTES.EXHIBITION_USER_DETAIL}/:id`, element: <ExhibitionUsersDetails handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.USER_MANAGEMENT, path: ROUTES.EXHIBITION_USER_DETAIL, element: <ExhibitionUsersDetails handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.INVENTORY_CONTROL_MANAGEMENT, path: ROUTES.MANAGE_INVENTORY, element: <InventoryManage handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.ORDER_MANAGEMENT, path: ROUTES.MANAGE_ORDER, element: <Manageorder handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.ORDER_MANAGEMENT, path: ROUTES.ORDER_DECILNE, element: <Decline handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.UNAUTHORIZED_VISIT, element: <UnauthorizedAccess /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.STORE_DETAILS, element: <StoreDetails handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.BILLING_INFO, element: <BillingInformation handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.PAYMENT_INFO, element: <PaymentInformation handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.PAYEE_INFO, element: <PayeeInformation handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.ORDER_MANAGEMENT, path: ROUTES.INITIAL_PAGE, element: <Navigate to={ROUTES.OMS} replace /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.ADMIN_AUTH, element: <AdminAuthentication login={loginFunc} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.ORDER_MANAGEMENT, path: ROUTES.SHIPPING_MULTIPLE_ORDER, element: <ShippingMultipleOrder handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.ORDER_MANAGEMENT, path: ROUTES.OMS, element: <Oms handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.ORDER_MANAGEMENT, path: `${ROUTES.OMS_CONFIRM_SHIPMENT}/:id`, element: <OMSConfirmShipment handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.PRINT_PACKING_SLIP, element: <PrintPackingSlip  /> },
        { category: newPermissionCategory.SUPER_ADMIN, path: ROUTES.ACTIVITY_LOG, element: <ActivityLog handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.ORDER_MANAGEMENT, path: `${ROUTES.REFUND_ORDER}/:id`, element: <RefundOrder handleLoader={handleLoader} showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.SALES_RECEIPT_MANAGEMENT, path: ROUTES.PAYMENT_REPORTS, element: <PaymentReports showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.SALES_RECEIPT_MANAGEMENT, path: ROUTES.SALES_HISTORY, element: <SalesHistory showSnackbar={showSnackbar} handleLoader={handleLoader} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.UNDER_MAINTENANCE, element: <UnderMaintenance showSnackbar={showSnackbar}  /> },
        { category: newPermissionCategory.MERCHANDISE_MANAGEMENT, path: ROUTES.SINGLE_PRODUCT_DETAILS, element: <SingleProductDetails showSnackbar={showSnackbar} handleLoader={handleLoader}/> },
        { category: newPermissionCategory.COMMON, path: ROUTES.PRODUCT_DOWNLOAD, element: <ProductFileDownload showSnackbar={showSnackbar}/> },
        { category: newPermissionCategory.COMMON, path: ROUTES.ERROR_DOWNLOAD_PRODUCT, element: <ErrorDownloadProduct/> },
        { category: newPermissionCategory.COMMON, path: ROUTES.PRODUCT_REPORT, element: <ProductReport showSnackbar={showSnackbar} /> },
        { category: newPermissionCategory.COMMON, path: ROUTES.ORDER_REPORT, element: <Report showSnackbar={showSnackbar} /> },
    ];


    return (
        <>
            {(showModal && loggedInUserType !== 0) && <ModalUtility msg={"Website has been put under maintenance"} modalOpen={isUnderMaintenance == "true" ? true : false} isReloadNeeded={true} keepTabOpen={true}/>}
            { modalOpen && <ModalUtility msg={t("pr.version")} modalOpen={modalOpen} setModalOpen={setModalOpen} isReloadNeeded={true} keepTabOpen={true}/>}

            <div className="App appMinHeight">

                <BrowserRouter>
                    <InternetConnection />

                    <ThemeProvider theme={theme}>
                        {cookie ?
                            location.pathname !== ROUTES.ADMIN_AUTH ?
                                loggedInUserType == CONSTANTS.USER_TYPE_SELLER ?
                                    isStoreCreated ? <Header logout={logoutFunc} setIsMsgRequired={setIsMsgRequired} isMsgRequired={isMsgRequired}  /> : null :
                                    <Header logout={logoutFunc} newSellerLogin={newSellerLogin} setNewSellerLogin={setNewSellerLogin} setIsMsgRequired={setIsMsgRequired} isMsgRequired={isMsgRequired} /> : null : null}
                        {cookie ?              
                            <Routes>
                                {
                                    loggedInUserType == CONSTANTS.USER_TYPE_ADMIN ? (
                                        routesListAfterLoginWithPermission.map((item, index) => (
                                            <Route key={index} path={item.path} element={<ProtectedRouteForAdmin category={item.category} route={item.path}>
                                                {item.element}
                                            </ProtectedRouteForAdmin>} />
                                        ))
                                    ) :
                                        (
                                            routesListAfterLogin.map((item, index) => (
                                                <Route key={index} path={item.path} element={<ProtectedRoute category={item.category} route={item.path}>
                                                    {item.element}
                                                </ProtectedRoute>} />
                                            ))
                                        )
                                }
                            </Routes>  : (
                           
                                <Routes>
                                    {routesListWithoutLogin.map((item, index) => (
                                        <Route key={index} path={item.path} element={item.element} />
                                    ))}
                                </Routes>
                            )}
                    </ThemeProvider>
                </BrowserRouter>
            </div>
            <Footer isCookiePresent={cookie} isMsgRequired={isMsgRequired}  />
        </>

    );
}

export default App;