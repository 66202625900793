import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath, devServerAPIPath } from "../config/apiPath";

export class UpdateInventory {

    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload =  {"entries": [payload]};
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async inventoryListUpdateData(productId: number) {

        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ?
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.MANAGE_INVENTORY_QUANTITY}`, this.payload).put() :
            await new AxiosUtils(`${API.BASE_URL}${devServerAPIPath.MANAGE_INVENTORY_QUANTITY}?products=${productId}`, this.payload, this.headers).put();

        if(response?.data?.success) {
            const updateMessage = response.data.message;

            return updateMessage;
        }
        else if(response?.data?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }
}

